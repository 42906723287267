define([
  'dojo/_base/declare',
  'dojo/_base/lang',
  'scramble/widgets/Modal',
  'dijit/_WidgetsInTemplateMixin',
  'dojo/i18n!scramble/widgets/modal/nls/alert',
  'dojo/text!scramble/templates/widgets/modal/alert.html',
  'scramble/widgets/_ContentTemplateMixin',
  'dijit/form/Button',
], (
  declare,
  lang,
  Modal,
  _WidgetsInTemplateMixin,
  nls,
  template,
  _ContentTemplateMixin,
) => declare([Modal, _WidgetsInTemplateMixin, _ContentTemplateMixin], {
  templateString: template,

  baseClass: 'modalAlert',

  title: null,

  continueLabel: null,

  description: 'Something happened.',

  constructor() {
    this.nls = lang.mixin({}, this.nls, nls);

    this.title = this.nls.alert;

    this.continueLabel = this.nls.okay;
  },

  _setDescriptionTextAttr(description) {
    this._set('descriptionText', description);
    this.description.textContent = description;
  },

  _setInnerHTMLAttr(html) {
    this._set('innerHTML', html);
    this.description.innerHTML = html;
  },

  postCreate() {
    this.inherited(arguments);
  },

  _onContinue() {
    this.success();
    this.hide();
  },

}));
