define([
  "dojo/_base/declare",
  "dojo/store/JsonRest",
  "dojo/request",
  "dojox/json/ref",
  "scramble/env"
], function(
  declare,
  JsonRest,
  request,
  json,
  env
) {

  return declare('scramble.stores.Session', JsonRest, {
    target: env.get('apiTarget') + "sessions",

    validate: function(username, password, options) {
      options = options || {};

      if (typeof username == 'object' && username.get) {
        password = username.get('password');
        username = username.get('username');
      }

      var options = dojo.mixin(options, {
        data: {'user[username]': username, 'user[password]': password},
        handleAs: 'json',
        headers: {
          'Accept': 'application/json'
        }
      });

      return request.post(this.target + '/validate', options);
    },

    validateByToken: function(token, dashboardId) {
      return request.post(this.target + '/validate', {
        data:  {"token": token, force_dashboard_id: dashboardId},
        handleAs: 'json',
        headers: {
          'Accept': 'application/json'
        }
      });
    },

    loginAs: function(customer_number) {
      return request.post(`${this.target}/login_as`, {
        data:  { customer_number },
        handleAs: 'json',
        headers: {
          'Accept': 'application/json'
        }
      });
    },

    logout: function() {
      return request.post(this.target + '/destroy');
    }
  });

});
