define([
  'dojo/i18n!./nls/common',
  'dojo/topic',
  'scramble-com/ui/public/offline-notification-modal',
  './widgets/ScrambleComModal',
  './env',
], (
  nls,
  topic,
  { default: OfflineNotificationModal },
  { default: createScrambleComModal },
  env,
) => {
  const offlineNotificationModal = createScrambleComModal({
    modalComponent: OfflineNotificationModal,
    nls,
  });

  const handleOffline = (e) => {
    if (!(e instanceof Event)) return;
    const isBuilder = env.get('document', false);

    offlineNotificationModal.show({
      isOpen: true,
      downloadOrderJSON: isBuilder
        ? () => topic.publish('/scramble/builder/dumpDocument')
        : undefined,
    });
  };

  const handleOnline = (e) => {
    if (!(e instanceof Event)) return;

    offlineNotificationModal.hide();
  };

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);
});
