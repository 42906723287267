define({
 root: ({
  "select_shipping_method": "Select Shipping Method",
  "estimate_disclaimer": ""
}),
  "zh-tw": true,
  "zh": true,
  "ja": true,
  "fr": true,
  "de": true,
  "ko": true,
  "it": true,
  "cs": true,
  "tr": true,
  "pl": true,
  "fi": true,
  "hu": true,
  "sk": true,
  "nb": true,
  "es": true,
  "nl": true,
  "pt": true,
  "ru": true,
  "sv": true
});
