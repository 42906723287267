define(["dojo/Deferred", "dojo/io-query", "scramble/has"], function(Deferred, ioQuery, has) {

  var iframe;

  function getIframeDocument(iframe) {
    var doc = iframe.contentWindow || iframe.contentDocument;
    return doc.document ? doc.document : doc;
  }

  function isEmptyObject(obj) {
    for(var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  function newTabDownload (url, timeout) {
    window.open(url, '_newtab');

    var dfd = new Deferred();
    dfd.resolve('download succeeded as far as i can tell?');
    return dfd;
  }

  function iframeDownload (url, timeout) {
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }

    iframe.src = url;
    var dfd = new Deferred();

    setTimeout(function() {
      var doc = getIframeDocument(iframe);
      if (doc && doc.body && doc.body.innerHTML.length > 0) {
        dfd.reject('download failed', doc.body.innerHTML);
      } else {
        dfd.resolve('download succeeded as far as i can tell?');
      }
    }, timeout);

    return dfd;
  }

  return function(url, timeout, /*object*/queryparams) {
    timeout = timeout || 10000;


    if(!isEmptyObject(queryparams)){
      // Assemble the new url with its query string attached.
      var queryStr = ioQuery.objectToQuery(queryparams);
      url = url + "?" + queryStr;
    }

    return has('ie') ? newTabDownload(url) : iframeDownload(url, timeout);
  };

});
