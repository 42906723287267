define(
  ({
    doc_type: 'Typ dokumentu',
    doc_date: 'Dátum dokumentu',
    doc_number: 'Číslo dokumentu',
    ean_thirteen: 'EAN-13',
    supply_date: 'Dátum dodania',
    discount: 'Zľava',
    sku: 'SKU',
    quantity_picked: 'Vyzdvihnuté množstvo',
    no_data_message: 'Žiadne záznamy',
    order_number: 'Č objednávky ERP',
    elastic_order_number: 'Č. objednávky Elastic',
    line_number: 'Č. riadka',
    customer_name: 'Meno zákazníka',
    customer_number: 'Č. zákazníka',
    location_number: 'Č. miesta',
    location_name: 'Názov miesta',
    product: 'Č. produktu',
    style_name: 'Názov produktu',
    code: 'Kód farby',
    color: 'Názov farby',
    status: 'Stav riadka',
    states: 'Stav objednávky',
    tracking: 'Č. sledovania',
    parcel_number: 'Č. zásielky',
    year: 'Rok',
    season: 'Obdobie',
    order_date: 'Dátum vytvorenia',
    cancel_date: 'Zrušiť podľa dátumu',
    invoice_date: 'Dátum faktúry',
    invoice: 'Č. faktúry',
    invoice_url: 'Webová adresa faktúry',
    download: 'Stiahnuť',
    view: 'Zobraziť',
    ship_date: 'Vyžiadaný dátum doručenia',
    expected_ship_date: 'Očakávaný dátum doručenia',
    terms_name: 'Názov podmienok',
    po_number: 'Č. objednávky',
    size: 'Veľkosť',
    alt_name: 'Dĺžka veľkosti',
    upc: 'UPC',
    units: 'Množstvo',
    unit_singular: 'Jednotka',
    unit_plural: 'Jednotky',
    amount: 'Suma',
    net_price: 'Netto',
    unit_price: 'Jednotková cena',
    unit_retail_price: 'Maloobchodná cena jednotky',
    orders: 'Objednávky',
    totals: 'Spolu',
    total: 'Spolu',
    details: 'Podrobnosti',
    open: 'Otvorené',
    shipped: 'Odoslané',
    cancelled: 'Zrušené',
    canceled: 'Zrušené',
    released: 'Uvoľnené',
    invoiced: 'Fakturované',
    picked: 'Vyzdvihnuté',
    region: 'Región',
    order_type: 'Typ objednávky',
    backorder_date: 'Dátum doobjednania',
    backorder: 'Doobjednanie',
    payment_terms: 'Platobné podmienky',
    ship_via: 'Doručiť cez',
    line_discount: 'Riadková zľava',
    rma_number: 'Č. RMA',
    cash_discount: 'Hotovostná zľava',
    hold_for_confirmation: 'Pre potvrdenie dodržte',
    credit_hold: 'Pozastavenie kreditu',
    order_notes: 'Poznámky k objednávke',
    product_series: 'Série produktov',
    product_category: 'Kategória produktov',
    product_sub_category: 'Podkategória produktov',
    quantity_confirmed: 'Potvrdené',
    quantity_backordered: 'Doobjednané',
    quantity_cancelled: 'Zrušené',
    quantity_shipped: 'Odoslané',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Katalóg',
    source: 'Zdroj',
    division: 'Divízia',
    actual_ship_date: 'Aktuálny dátum odoslania',
    rep_name: 'Meno zástupcu',
    tracking_carrier: 'Kód dopravcu',
    gross_amount: 'Suma v hrubom',
    discount_percent: '% zľava',
    internal_code: 'Interný kód',
    requested_date: 'Požadovaný dátum',
    net_amount_total: 'Celková suma v čistom',
    freight_amount: 'Suma dopravy',
    client_order_status: 'Stav objednávky',
    ship_address: 'Adresa doručenia',
    ship_city: 'Mesto doručenia',
    ship_state: 'Stav doručenia',
    warehouse_name: 'Názov skladu',
    client_carrier_code: 'Kód dopravcu klienta',
    discount_1: 'Zľava 1',
    discount_2: 'Zľava 2',
    discount_3: 'Zľava 3',
    brand: 'Značka',
    quantity_open: 'Množstvo otvorené',
    amount_open: 'Suma otvorená',
    quantity_invoiced: 'Fakturované množstvo',
    amount_invoiced: 'Fakturovaná suma',
    promo_code: 'Promo kód',
    size_code: 'Kód veľkosti',
    due_date: 'Termín',
    quantity_ordered: 'Objednané množstvo',
    quantity_allocated: 'Priradené množstvo',
    available_date: 'Dostupný dátum',
    bank_information: 'Bankové údaje',
    shipment_number: 'Č. doručenia',
    quantity_shipped_sku: 'SKU zásielky',
    name: 'Názov',
    customs_reference: 'Colná referencia',
    currency_code: 'Kód meny',
    id_model_partenaire_1: 'ID modelu partnera',
    id_model_partenaire_2: 'ID modelu partnera 2',
    expected_ship_date_range: 'Očakávané obdobie doručenia',
    hs_code: 'Kód HS',
  }),
);
