define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "dojo/Stateful",
  "dojo/Evented",
  "scramble/models/_ChildPropertyCacher",
  "dojox/mvc/StatefulArray",
  "dojox/mvc/getPlainValue",
  "dojox/mvc/getStateful"
], function(
  declare,
  lang,
  array,
  Stateful,
  Evented,
  _ChildPropertyCacher,
  StatefulArray,
  getPlainValue,
  getStateful
) {

  return declare([Stateful, Evented, _ChildPropertyCacher], {

    _fields: {},

    constructor: function() {
      // setup defaults
      lang.mixin(this, this._fields);
    },

    postscript: function() {
      this.inherited(arguments);
      this._started = true;
    },

    // specifically designed for cloning models
    clone: function() {
      function cloneHelper(o) {
        if (o && o.set && o.get) {
          // it's a stateful
          if (o.clone) {
            // with a clone method
            return o.clone();
          }

          if (o.length) {
            // StatefulArray
            return new StatefulArray(array.map(o, function(i) {
              return cloneHelper(i);
            }));
          }

          // other statefuls
          return getStateful(getPlainValue(o));
        }

        return lang.clone(o);
      }

      var obj = {};
      for (var key in this._fields) {
        obj[key] = cloneHelper(this.get(key));
      }

      return new this.constructor(obj);
    }

  });

});