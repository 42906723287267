define([
  'scramble/env',
  'dojo/_base/lang',
  'scramble-com/modules/abstract-catalog',
],
(
  env,
  lang,
  { normalizedBrand },
) => {
  const recipeBrandLogo = type => brand => lang.getObject(
    `images.${type}.${normalizedBrand(brand)}`,
    false,
    env.get('appConfig'),
  ) && encodeURI(lang.getObject(
    `images.${type}.${normalizedBrand(brand)}`,
    false,
    env.get('appConfig'),
  ));

  const recipeCatalogBrandLogo = brand => recipeBrandLogo('catalogBrandLogos')(brand);

  const recipeCatalogBrandWhiteboardLogo = brand => recipeBrandLogo(
    'catalogBrandWhiteboardLogos',
  )(brand);

  const imageFromClientBundle = (mid = '') => {
    if (!mid.match(/^client\//)) return null;
    const id = `image_${mid.split('/')
      .slice(-1)[0]
      .replace(/\./g, '_')
      .replace(/-/g, '_')}`;

    return (
      window.clientBundle
      && window.clientBundle.images
      && window.clientBundle.images[id]
    )
      || null;
  };

  const imageUrl = (imageObj = {}, imageName) => {
    const clientImage = imageObj[imageName];

    const clientImageFileUrl = imageFromClientBundle(clientImage);
    if (clientImageFileUrl) return clientImageFileUrl;

    return (imageObj[imageName] ? require.toUrl(clientImage) : '');
  };

  const appendImage = (domNode, priority = '', imageName) => {
    if (!domNode) throw new Error('appendImage called without domNode');
    const imgUrl = ({ images } = {}) => imageUrl(images, imageName);
    const setUrl = url => url
        && domNode.style.setProperty('background-image', `url(${url})`, priority);

    env
      .whenDefined('appConfig')
      .then(imgUrl)
      .then(setUrl);
  };

  const background = (domNode, priority) => {
    appendImage(domNode, priority, 'mainBackground');
  };

  const findExisting = array => array.find(Boolean);

  const brandLogo = brand => findExisting([
    recipeCatalogBrandLogo(brand),
    window.clientBundle && window.clientBundle.images && window.clientBundle.images[`image_${brand}_png`],
  ]);

  const logo = (domNode, priority = 'important') => {
    const setLogo = (brand = env.get('brand')) => (
      brand
        ? domNode.style.setProperty(
          'background-image',
          `url(${brandLogo(brand)})`,
          priority,
        )
        : appendImage(domNode, priority, 'logo')
    );

    if (env.getFlag('catalogBrands')) {
      env.watch('brand', (name, oldBrand, brand) => setLogo(brand));
    }
    setLogo();
  };

  // logoMid is the Scramble4 asset, logoUrl is the Recipe asset
  const s3wbLogo = () => env.getFlag('whiteboard.logoUrl');

  const scrambleBrandWBLogo = brand => require.toUrl(findExisting([
    brand && window.clientBundle && window.clientBundle.images && window.clientBundle.images[`image_header_${brand}_png`],
    window.clientBundle && window.clientBundle.images && window.clientBundle.images[`image_wb_logo_png`],
  ]));

  const brandWhiteboardLogo = brand => (
    brand
      && recipeCatalogBrandWhiteboardLogo(brand)
  )
    || scrambleBrandWBLogo(brand);

  const wbLogo = (
    brand = env.get('brand'),
    s3logo = imageFromClientBundle(s3wbLogo()) || s3wbLogo(),
  ) => ((!brand && s3logo)
    ? s3logo
    : imageFromClientBundle(brandWhiteboardLogo(brand))) || brandWhiteboardLogo(brand);

  return {
    background,
    logo,
    wbLogo,
    imageUrl,
    brandLogo,
  };
});
