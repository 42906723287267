define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array"
], function(
  declare,
  lang,
  array
) {

  return declare([], {
    /*
    childPropertyCaches: [
      {
        child: 'children',
        property: 'quantity',
        callback: function (property, newValue, oldValue) {...},
        initialize (optional): function(propertyValue) {...}
      }
    ]
    */
    childPropertyCaches: [],

    _childPropertyWatches: null,

    constructor: function() {
      // we have to use maps for all this stuff because it's using object keys.
      // Object only supports string keys.
      this._childPropertyWatches = new Map();
    },

    set: function(name, value) {
      this.inherited(arguments);

      if (name && this._getChildPropertyCacheKeys().indexOf(name) != -1) {
        this._setupChildPropertyCachesForChild(name);
      }
    },

    _setupChildPropertyCachesForChild: function(child) {
      var caches = array.filter(this.childPropertyCaches, function(cache) {
        return cache.child === child;
      });

      array.forEach(caches, lang.hitch(this, this._watchChildProperty));
    },

    _watchChildProperty: function(cacheDef) {
      var child = this.get(cacheDef.child);

      if (!child.watchElements) {
        throw "child.watchElements is not defined for " + child + ". " +
          "_ChildPropertyCacher currently only works for child objects which " +
          "respond to watchElements such as dojox/mvc/StatefulArray.";
      }

      this._unwatchChildPropertyOf(cacheDef);
      this._watchChildPropertyOf(child, cacheDef);
      this._callChildPropertyCacheInitializer(child, cacheDef);

      child.watchElements(lang.hitch(this, function(idx, removals, adds) {
        // just initialize no matter what for right now. this is dumb and should
        // be improved upon.
        this._callChildPropertyCacheInitializer(child, cacheDef);

        if (!removals && !adds) {
          this._unwatchChildPropertyOf(cacheDef);
          this._watchChildPropertyOf(child, cacheDef);
        } else {
          if (removals) {
            this._unwatchChildPropertyOf(cacheDef, removals);
          }

          if (adds) {
            this._watchChildPropertyOf(adds, cacheDef);
          }
        }
      }));
    },

    _watchChildPropertyOf: function(items, cacheDef) {
      array.forEach(items, lang.hitch(this, function(item) {
        var watches = [];
        var handle = item.watch(cacheDef.property, lang.hitch(this, function(name, oldValue, newValue) {
          lang.hitch(this, cacheDef.callback)(name, oldValue, newValue, item);
        }));

        if (!this._childPropertyWatches.get(cacheDef)) {
          this._childPropertyWatches.set(cacheDef, new Map());
        }

        this._childPropertyWatches.get(cacheDef).set(item, handle);
      }));
    },

    _unwatchChildPropertyOf: function(cacheDef, items) {
      if (!cacheDef) {
        var keys = this._mapToKeys(this._childPropertyWatches);
        array.forEach(keys, function(cacheDef) {
          var cacheItems = this._childPropertyWatches.get(cacheDef);
          var itemKeys = this._mapToKeys(cacheItems);
          array.forEach(itemKeys, function(item) {
            cacheItems.get(item).remove();
            cacheItems['delete'](item);
          }, this);

          this._childPropertyWatches['delete'](cacheDef);
        }, this);

        return;
      }

      var cacheItems = this._childPropertyWatches.get(cacheDef);
      if (!cacheItems) {
        return;
      }

      var keys = this._mapToKeys(cacheItems);
      array.forEach(keys, function(item) {
        if (items) {
          if (array.indexOf(items, item) != -1) {
            var handle = cacheItems.get(item);
            if (!handle) {
              return;
            }

            handle.remove();
            cacheItems['delete'](item);
          }
        } else {
          var handle = cacheItems.get(item);
          if (!handle) {
            return;
          }

          handle.remove();
          cacheItems['delete'](item);
        }
      }, this);
    },

    _mapToKeys: function(map){
      if (!map.keys) {
        var keys = [];
        map.forEach(function(val, key) {
          keys.push(key);
        });

        return keys;
      }

      var keys = map.keys();
      if(keys instanceof Array){
        // original method
        return Array.prototype.constructor.apply(null, keys);
      } else if (keys && keys.next) {
        // Firefox returns an Iterator back instead of an array, unfortunately
        // iterators can't be coerced into Array so we have to loop through the items
        var keysArray = [];
        try{
          var key = null;
          // Iterator#next returns an object like {done: <bool>, value: <key object>}
          while((key = keys.next()) && !key.done) {
            keysArray.push(key.value);
          }
        } catch(e) {
          // apparently this throws a StopIteration error sometimes
        }
        return keysArray;
      }

      // apparently safari doesn't support any of the APIs above.
      try {
        var keysArray = [];
        (new Function('keys', 'keysArray', 'for (var key of keys) { keysArray.push(key); }'))(keys, keysArray);
        return keysArray;
      } catch(e) {
        console.warn(e);
      }

      throw Error("Something incredibly wrong has happend");
    },

    _getChildPropertyCacheKeys: function() {
      return array.map(this.childPropertyCaches, function(cache) {
        return cache.child;
      });
    },

    _callChildPropertyCacheInitializer: function(child, cacheDef) {
      lang.hitch(this, cacheDef.initialize, child)();
    }
  });

});
