define(
  ({
    doc_type: 'Documenttype',
    doc_date: 'Documentdatum',
    doc_number: 'Documentnummer',
    ean_thirteen: 'EAN-13',
    supply_date: 'Leverdatum',
    discount: 'Korting',
    sku: 'SKU',
    quantity_picked: 'Gekozen hoeveelheid',
    no_data_message: 'Geen gegevens',
    order_number: 'Bestellings #',
    elastic_order_number: 'Elastic bestelling #',
    line_number: 'Regel #',
    customer_name: 'Klantnaam',
    customer_number: 'Klant #',
    location_number: 'Locatie #',
    location_name: 'Locatienaam',
    product: 'Product #',
    style_name: 'Productnaam',
    code: 'Kleurcode',
    color: 'Kleurnaam',
    status: 'Lijnstatus',
    states: 'Bestellingsstatus',
    tracking: 'Tracking #',
    parcel_number: 'Pakket #',
    year: 'Jaar',
    season: 'Seizoen',
    order_date: 'Datum aangemaakt',
    cancel_date: 'Annuleren voor',
    invoice_date: 'Factuurdatum',
    invoice: 'Factuur #',
    invoice_url: 'Factuur-URL',
    download: 'Downloaden',
    view: 'Weergeven',
    ship_date: 'Gewenste verzendingsdatum',
    expected_ship_date: 'Verwachte verzendingsdatum',
    terms_name: 'Naam voorwaarden',
    po_number: 'PO #',
    size: 'Formaat',
    alt_name: 'Formaat Lengte',
    upc: 'UPC',
    units: 'Hoev.',
    unit_singular: 'Unit',
    unit_plural: 'Units',
    amount: 'Hoeveelheid',
    net_price: 'Met korting',
    unit_price: 'Prijs eenheid',
    unit_retail_price: 'Verkoopprijs per stuk',
    orders: 'Bestellingen',
    totals: 'Totaal',
    total: 'Totaal',
    details: 'Details',
    open: 'Open',
    shipped: 'Verzonden',
    cancelled: 'Geannuleerd',
    canceled: 'Geannuleerd',
    released: 'Vrijgegeven',
    invoiced: 'Gefactureerd',
    picked: 'Picked',
    region: 'Regio',
    order_type: 'Bestellingstype',
    backorder_date: 'Datum reservering',
    backorder: 'Reservering',
    payment_terms: 'Betalingstermijn',
    ship_via: 'Verzenden via',
    line_discount: 'Regelkorting',
    rma_number: 'RMA #',
    cash_discount: 'Cashkorting',
    hold_for_confirmation: 'Vastgehouden voor bevestiging',
    credit_hold: 'Credit Hold',
    order_notes: 'Bestellingsnotities',
    product_series: 'Productserie',
    product_category: 'Productcategorie',
    product_sub_category: 'Productsubcategorie',
    quantity_confirmed: 'Bevestigd',
    quantity_backordered: 'Gereserveerd',
    quantity_cancelled: 'Geannuleerd',
    quantity_shipped: 'Verzonden',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Catalogus',
    source: 'Bron',
    division: 'Afdeling',
    actual_ship_date: 'Daadwerkelijke verzenddatum',
    rep_name: 'Naam vertegenwoordiger',
    tracking_carrier: 'Code carrier',
    gross_amount: 'Bruto aantal',
    discount_percent: 'Kortings-%',
    internal_code: 'Interne code',
    requested_date: 'Gewenste datum',
    net_amount_total: 'Totaal netto-bedrag',
    freight_amount: 'Vrachtbedrag',
    client_order_status: 'Bestelstatus',
    ship_address: 'Verzendadres',
    ship_city: 'Verzendingsplaats',
    ship_state: 'Deelstaat verzending',
    warehouse_name: 'Naam magazijn',
    client_carrier_code: 'Transportcode klant',
    discount_1: 'Korting 1',
    discount_2: 'Korting 2',
    discount_3: 'Korting 3',
    brand: 'Merk',
    quantity_open: 'Hoeveelheid open',
    amount_open: 'Bedrag open',
    quantity_invoiced: 'Gefactureerde hoeveelheid',
    amount_invoiced: 'Gefactureerd bedrag',
    promo_code: 'Promotiecode',
    size_code: 'Code formaat',
    due_date: 'Vervaldatum',
    quantity_ordered: 'Bestelde hoeveelheid',
    quantity_allocated: 'Toegewezen hoeveelheid',
    available_date: 'Beschikbaarheidsdatum',
    bank_information: 'Bankgegevens',
    shipment_number: 'Zending #',
    quantity_shipped_sku: 'SKU verzending',
    name: 'Naam',
    customs_reference: 'Referentie douane',
    currency_code: 'Valuta-code',
    id_model_partenaire_1: 'Partnermodel-ID',
    id_model_partenaire_2: 'Partnermodel-ID 2',
    expected_ship_date_range: 'Datumbereik verwachte verzending',
    hs_code: 'GS-code',
  }),
);
