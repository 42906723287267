define({
 root: ({
  "load_error": "Load Error",
  "description": "A request did not complete correctly. You should make sure your order is saved then refresh this page."
}),
  "zh-tw": true,
  "zh": true,
  "ja": true,
  "fr": true,
  "de": true,
  "ko": true,
  "it": true,
  "cs": true,
  "tr": true,
  "pl": true,
  "fi": true,
  "hu": true,
  "sk": true,
  "en-gb": true,
  "nb": true,
  "es": true,
  "nl": true,
  "pt": true,
  "ru": true,
  "sv": true
});
