define([
  'dojo/_base/declare',
  'dojox/app/Controller',
  'scramble/env',
], (
  declare,
  Controller,
  env,
) => declare(Controller, {
  constructor() {
    if (env.getFlag('externalSupport.provider') !== 'genesys') return;
    const genesysScript = document.createElement('script');
    genesysScript.type = 'text/javascript';
    genesysScript.innerHTML = '(function (g, e, n, es, ys) {'
      + "g['_genesysJs'] = e;"
      + 'g[e] = g[e] || function () {'
      + '(g[e].q = g[e].q || []).push(arguments)'
      + '};'
      + 'g[e].t = 1 * new Date();'
      + 'g[e].c = es;'
      + "ys = document.createElement('script');"
      + 'ys.async = 1;'
      + 'ys.src = n;'
      + "ys.charset = 'utf-8';"
      + 'document.head.appendChild(ys);'
      + "})(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {"
      + "environment: 'prod-usw2',"
      + "deploymentId: '40416803-92e7-45a9-87f4-b6ef4ea8b86d'"
      + '});';
    document.getElementsByTagName('head')[0].append(genesysScript);
  },
}));
