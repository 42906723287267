define(
  ({
    doc_type: 'Dokumenttyp',
    doc_date: 'Dokumentdatum',
    doc_number: 'Dokumentnummer',
    ean_thirteen: 'EAN-13',
    supply_date: 'Leveransdatum',
    discount: 'Rabatt',
    sku: 'SKU',
    quantity_picked: 'Antal plockade',
    no_data_message: 'Uppgifter saknas',
    order_number: 'Beställningsnummer',
    elastic_order_number: 'Elastic-beställningsnummer',
    line_number: 'Radnummer',
    customer_name: 'Köparens namn',
    customer_number: 'Kundnummer',
    location_number: 'Platsnummer',
    location_name: 'Platsnamn',
    product: 'Produktnummer',
    style_name: 'Produktnamn',
    code: 'Färgkod',
    color: 'Färgnamn',
    status: 'Linjestatus',
    states: 'Beställningsstatus',
    tracking: 'Spårningsnummer',
    parcel_number: 'Försändningsnummer',
    year: 'År',
    season: 'Säsong',
    order_date: 'Datum skapad',
    cancel_date: 'Avbeställningsdatum',
    invoice_date: 'Fakturadatum',
    invoice: 'Fakturanummer',
    invoice_url: 'Fakturans webbadress',
    download: 'Ladda ner',
    view: 'Visa',
    ship_date: 'Önskat leveransdatum',
    expected_ship_date: 'Förväntat leveransdatum',
    terms_name: 'Villkorsnamn',
    po_number: 'Beställningsnummer',
    size: 'Storlek',
    alt_name: 'Storlek Längd',
    upc: 'UPC (streckkod)',
    units: 'Antal',
    unit_singular: 'Paketenhet',
    unit_plural: 'Paketenheter',
    amount: 'Belopp',
    net_price: 'Netto',
    unit_price: 'Styckpris',
    unit_retail_price: 'Styckpris',
    orders: 'Beställningar',
    totals: 'Totalbelopp',
    total: 'Totalt',
    details: 'Detaljuppgifter',
    open: 'Öppen',
    shipped: 'Levereras',
    cancelled: 'Inställt',
    canceled: 'Inställt',
    released: 'Släppt',
    invoiced: 'Fakturerad',
    picked: 'Plockad',
    region: 'Region',
    order_type: 'Beställningstyp',
    backorder_date: 'Restorderdatum',
    backorder: 'Restorder',
    payment_terms: 'Betalningsvillkor',
    ship_via: 'Leverera via',
    line_discount: 'Mängdrabatt',
    rma_number: 'Returnummer',
    cash_discount: 'Kontant rabatt',
    hold_for_confirmation: 'Håll nedtryckt för att bekräfta',
    credit_hold: 'Kreditspärr',
    order_notes: 'Beställningsanteckningar',
    product_series: 'Produktserie',
    product_category: 'Produktkategori',
    product_sub_category: 'Produktunderkategori',
    quantity_confirmed: 'Bekräftad',
    quantity_backordered: 'Restorder',
    quantity_cancelled: 'Inställd',
    quantity_shipped: 'Levererad',
    ivendix_order_id: 'iVendix-ID',
    catalog: 'Katalog',
    source: 'Källa',
    division: 'Avdelning',
    actual_ship_date: 'Faktiskt leveransdatum',
    rep_name: 'Säljarnamn',
    tracking_carrier: 'Transportörskod',
    gross_amount: 'Bruttobelopp',
    discount_percent: 'Rabatt %',
    internal_code: 'Intern kod',
    requested_date: 'Beställningsdatum',
    net_amount_total: 'Nettobelopp',
    freight_amount: 'Fraktkostnad',
    client_order_status: 'Beställningsstatus',
    ship_address: 'Leveransadress',
    ship_city: 'Leveransort',
    ship_state: 'Fraktstatus',
    warehouse_name: 'Lagernamn',
    client_carrier_code: 'Kundens transportörskod',
    discount_1: 'Rabatt 1',
    discount_2: 'Rabatt 2',
    discount_3: 'Rabatt 3',
    brand: 'Varumärke',
    quantity_open: 'Öppet antal',
    amount_open: 'Öppet belopp',
    quantity_invoiced: 'Fakturerat antal',
    amount_invoiced: 'Fakturerat belopp',
    promo_code: 'Kampanjkod',
    size_code: 'Storlekskod',
    due_date: 'Förfallodatum',
    quantity_ordered: 'Beställt antal',
    quantity_allocated: 'Tilldelat antal',
    available_date: 'Datum i lager',
    bank_information: 'Bankuppgifter',
    shipment_number: 'Leveransnummer',
    quantity_shipped_sku: 'Skickat SKU',
    name: 'Namn',
    customs_reference: 'Tullreferens',
    currency_code: 'Valutakod',
    id_model_partenaire_1: 'Partnermodell-ID',
    id_model_partenaire_2: 'Partnermodell-ID 2',
    expected_ship_date_range: 'Förväntat datumintervall för leverans',
    hs_code: 'HS-kod',
  }),
);
