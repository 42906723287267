define(
  ({
    new_order_document: 'Pedido Elastic',
    no_quantities: 'Debes seleccionar una cantidad mínima de 1 para enviar el pedido',
    no_customer_number: 'Seleccionar dirección de facturación para ',
    no_location_number: 'Seleccionar dirección de envío para ',
    no_arrival_date: 'Seleccionar una fecha de envío para',
    no_cancel_date: 'Seleccionar un fecha de cancelación para',
    invalid_cancel_date: 'Selecciona una fecha de cancelación válida.',
    invalid_start_ship_date: 'Selecciona una fecha de expedición válida para',
    no_po_number: 'Introducir número del pedido para',
    po_number_duplicate: 'Los números de pedido deben ser distintos para cada pedido.',
    invalid_po_number: 'Por favor, introduce un número de PO válido',
    invalid_page_comment: 'Ingresa un comentario válido',
    products_not_available: 'Hay algunos productos en el carro que no estarán disponibles para la fecha de envío seleccionada.',
    already_submitted: 'Este pedido ya ha sido enviado',
    already_review: 'Ya has enviado este pedido a tu representante, debe revisarse.',
    shared_with: 'Este documento se ha compartido con un distribuidor. Se trata de un documento de sólo lectura, que no puede ser procesado',
    dealer_submit: 'Este documento se encuentra pendiente de revisión por parte de tu gestor, por lo que aún no se puede procesar',
    page: 'Página',
    shipment: 'Envío',
    external_field: 'Selecciona ### para ',
    credit_hold_explanation: 'Con tu estado de cuenta actual, puedes crear un pedido, pero no podrás enviarlo. Por favor, ponte en contacto con el Gestor de Crédito.',
    catalog_min_failure: 'Debe tener al menos ###unidades para este catálogo.',
    catalog_max_failure: 'Se ha superado el límite de ### unidades para este catálogo.',
    catalog_min_amount_failure: 'La cantidad mínima de pedido para este catálogo es ###.',
    catalog_max_amount_failure: 'La cantidad máxima de pedido para este catálogo es ###.',
    catalog_price_group_failure: 'No se puede realizar el pedido porque falta un grupo de precios.',
    catalog_requires_embellishments: 'Todos los artículos tienen que ser personalizados.',
    arrive_on_is_in_past: 'La fecha de inicio de envío está en el pasado para ###. Por favor, actualízalas para que sean hoy o en el futuro.',
    shipping_account_number_invalid: 'Introduce un número de cuenta de compras válido',
    document_renamed: 'Pedido renombrado.',
    document_could_not_be_renamed: 'El pedido no se ha podido renombrar',
    has_placeholder_allocations: 'Algunos artículos no están disponibles para tu fecha de envío solicitada. Para continuar, es necesario resolver el problema.',
  }),
);
