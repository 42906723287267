define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "dojox/app/Controller",
  "scramble/env",
  "dojo/cookie"
], function(
  declare,
  lang,
  array,
  Controller,
  env,
  cookie
) {

  return declare(Controller, {
    cookieName: 'scramble_cms_admin',

    constructor: function(app, events) {
      env.watch('user', lang.hitch(this, function(n, o, user) {
        if (!user) {
          this._clearAdminCookie();
        } else if (array.indexOf(['lyle.rep', 'ben.rep', 'asmits', 'eurep', 'usrep'], user.username) != -1) {
          this._setAdminCookie();
        }
      }));
    },

    _clearAdminCookie: function() {
      cookie(this.cookieName, null, {expires: -1, path: '/'});
      cookie(this.cookieName, null, {expires: -1, path: '/',
        domain: env.get('apiHost')});
    },

    _setAdminCookie: function() {
      cookie(this.cookieName, true, {path: '/', Secure: true, SameSite: 'Strict'});
      cookie(this.cookieName, true, {path: '/',
        domain: env.get('apiHost'), Secure: true, SameSite: 'Strict'});
    }
  });

});
