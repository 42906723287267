define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojox/app/Controller",
  "scramble/has",
  "scramble/env",
  "dojo/aspect",
  "dojo/dom-class",
  "dijit/focus"
], function(
  declare,
  lang,
  Controller,
  has,
  env,
  aspect,
  domClass,
  focus
) {

  return declare(Controller, {
    constructor: function(app, events) {
      // the reason this exists is because packery makes this stupid require call to check
      // if jquery is available, which fucks up the ready state when it 404s. so i'm overriding
      // require entirely and stripping out deps which require "jquery". in theary this
      // shouldn't cause problems elsewhere. hopefully it doesn't fuck up the build.
      var amd = define.amd;

      aspect.around(window, 'define', function(originalRequire) {
        return function(mid, deps) {
          if (deps && deps.length && deps.indexOf) {
            var idx = deps.indexOf('jquery');
            if (idx > -1) {
              deps.splice(idx, 1);
            }
          }
          return originalRequire.apply(window, arguments);
        };
      });

      define.amd = amd;

      if(has("tablet")) {
        document.body.addEventListener('touchmove', function(event){
          // event.preventDefault();
        }, false);

        aspect.before(focus,'_onTouchNode', function(node, by){
          // There is no other way to override this function
          // This specifies that it isn't the mouse cuasing this so that there
          // isn't an additional focus event from dijit that ends up firing before
          // the iPad's touch event, thus selecting incorrect fields on the iPad
          return [node, "finger"];
        });
      } else {
        document.body.addEventListener('keydown', function(e){
          if ( e.keyCode === 8 && e.target.tagName === 'BODY' ) {
            e.preventDefault();
          }
        }, false);
      }

      if (env.getFlag('separateInfinityStyle')) {
        domClass.add(document.body, "separate_infinity_style");
      }

      const handleOrientationChange = () => {
        setTimeout(() => {
          const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

          const h = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

          const [widget] = Object.values(app.children).filter(child => child._active)

          if (widget && typeof widget.resize === 'function') widget.resize({ h, w })
        }, 100);
      }

      const SO = window.ScreenOrientation;

      if (Boolean(has('ios'))) {
        if (SO) {
          SO.onchange = handleOrientationChange;
        } else {
          window.addEventListener('orientationchange', handleOrientationChange);
        }
      }
    }
  });

});
