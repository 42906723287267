define(
  ({
    new_order_document: 'Objednávka Elastic',
    no_quantities: 'Ak chcete odoslať objednávku, musíte vybrať množstvo aspoň 1.',
    no_customer_number: 'Vyberte fakturačnú adresu pre ',
    no_location_number: 'Vyberte doručovaciu adresu pre ',
    no_arrival_date: 'Vyberte dátum začiatku dopravy pre',
    no_cancel_date: 'Vyberte dátum zrušenia pre',
    invalid_cancel_date: 'Vyberte platný dátum zrušenia.',
    invalid_start_ship_date: 'Vyberte platný dátum začiatku dopravy pre',
    no_po_number: 'Zadajte číslo nákupnej objednávky pre',
    po_number_duplicate: 'Čísla nákupných objednávok musia byť pre každú objednávku jedinečné.',
    invalid_po_number: 'Zadajte platné číslo nákupnej objednávky',
    invalid_page_comment: 'Zadajte, prosím, platný komentár',
    products_not_available: 'V košíku máte produkty, ktoré nebudú dostupné vo vybranom dátume odoslania.',
    already_submitted: 'Táto objednávka už bola odoslaná.',
    already_review: 'Túto objednávku ste svojmu zástupcovi už odoslali. Je potrebné skontrolovať ju.',
    shared_with: 'Dokument bol zdieľaný s predajcom. Je určený iba na čítanie a nedá sa odoslať',
    dealer_submit: 'Dokument čaká na kontrolu vaším zástupcom a nemožno ho odoslať',
    page: 'Stránka',
    shipment: 'Preprava',
    external_field: 'Vyberte ### pre ',
    credit_hold_explanation: 'So svojím súčasným stavom účtu môžete vytvoriť objednávku, ale nebudete ju môcť odoslať. Kontaktujte svojho správcu kreditov.',
    catalog_min_failure: 'Pre tento katalóg musíte mať aspoň ### jednotiek.',
    catalog_max_failure: 'Pre tento katalóg ste presiahli limit ### jednotiek.',
    catalog_min_amount_failure: 'Minimálna celková suma objednávky pre tento katalóg je ###.',
    catalog_max_amount_failure: 'Maximálna celková suma objednávky pre tento katalóg je ###.',
    catalog_price_group_failure: 'Objednávku nemožno zadať z dôvodu chýbajúcej cenovej skupiny.',
    catalog_requires_embellishments: 'Všetky položky musia byť prispôsobené.',
    arrive_on_is_in_past: 'Dátum začiatku doručenia pre položku ### je v minulosti. Aktualizujte tento dátum na dnes alebo dátum v budúcnosti.',
    shipping_account_number_invalid: 'Zadajte, prosím, platné číslo účtu doručenia',
    document_renamed: 'Objednávka premenovaná.',
    document_could_not_be_renamed: 'Objednávku sa nepodarilo premenovať.',
    has_placeholder_allocations: 'Niektoré položky nie sú dostupné vo váš požadovaný dátum doručenia. Ak chcete pokračovať, musíte to vyriešiť.',
  }),
);
