define([
  "dojo/_base/declare",
  "dojox/app/Controller",
  "dojo/topic",
  "scramble/env",
], function(
  declare,
  Controller,
  topic,
  env,
) {
  return declare(Controller, {
    constructor: function() {
      env.whenDefined('appConfig')
        .then(() => {
          if (env.getFlag('externalSupport.provider') !== 'salesforce') return;

          const scriptUrl = env.getFlag('externalSupport.scriptUrl');

          if (!scriptUrl) return;

          require(["scramble/util/salesforce-chat-bot"], ({
            appendBot,
            moveChatButton,
          }) => {
            env.watch('user', function(name, old, user) {
              if (name !== 'user') return;

              if (!user) return;

              const appConfig = env.get('appConfig');

              if (!appConfig) return;

              appendBot({
                user,
                scriptUrl,
              });

              topic.subscribe('scramble/viewTransitionComplete', moveChatButton);
            });
          });
        }, error => {
          console.error("Error awaiting app configuration", error);
        })

    }
  });

});
