define(
  ({
    continue: 'Kontynuuj',
    cancel: 'Anuluj',
    current_customization_will_be_reset: 'Bieżące dostosowanie zostanie zresetowane',
    rogue_warning: 'Jeśli będziesz kontynuować, Twoja bieżąca konfiguracja zostanie zresetowana i proces dostosowywania zostanie wznowiony. Na pewno chcesz kontynuować?',
    copy: 'Kopiuj',
    paste: 'Wklej',
    copyToAll: 'Kopiuj do wszystkich',
    add_to_cart: 'Dodaj do koszyka',
    addToCart: 'Dodaj do koszyka',
    remove: 'Usuń',
    added: 'Dodano',
    dropped: 'Usunięty',
    units: 'Jednostki',
    total: 'Łącznie',
    subtotal: 'Suma częściowa',
    moveToShipment: 'Przenieś do wysyłki',
    chooseOption: 'Wybierz opcję',
    availability_msg: 'Niektóre żądane ilości są niedostępne w dniu wysyłki',
    atsWarning: 'Ta ilość nie będzie dostępna do %{availableOn}.',
    inventory_warehouse: 'Magazyn zapasów',
    notInCatalog: 'Nie można dodać produktu do koszyka',
    notInCatalogExplanation: 'Przepraszamy, dodawanie produktów między katalogami nie jest dostępne.',
    digital_market_notInCatalogExplanation: 'Twój koszyk może zawierać tylko produkty jednej marki na raz',
    removeFromCartSuggestion: 'Czy chcesz <link>opróżnić koszyk?</link>',
    digital_market_removeFromCartSuggestion: 'Możesz <link>opróżnić koszyk</link>, aby dodać ten element',
    emptyCart: 'Opróżnij koszyk',
    empty: 'Pusty',
    emptyConfirmation: 'Czy na pewno chcesz opróżnić koszyk?',
    available_on: 'Dostępne w dniu',
    personalization: 'Personalizacja',
    personalize: 'Personalizuj',
    configuration: 'Konfiguracja',
    configure: 'Dostosuj',
    placement: 'Umiejscowienie',
    content: 'Treść',
    color: 'Kolor',
    product_specification: 'Parametry produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Tekst',
    text_and_logo: 'Tekst, logo',
    other: 'Inny',
    value_might_be_stale: 'Z powodu zmian w koszyku ta wartość może być nieaktualna. Zapisz zamówienie, aby przeliczyć ponownie.',
    more_details: 'Więcej szczegółów',
    availability: 'Dostępność',
    no_scheduled_availability: 'Brak zaplanowanej dostępności',
    not_available_until: 'Niedostępne do <date />',
    available_as_of: 'Dostępne od <date />',
    units_bundled_singular_label: '%{units} jednostka zgrupowana',
    units_bundled_label: '%{units} jednostki zgrupowane',
    add_bundle_to_cart: 'Dodaj grupę do koszyka',
    bundles: 'Grupy',
    bundled: 'Zgrupowane',
    product: 'Produkt',
    sizes_and_quantities: 'Rozmiar / Ilość',
    units_singular_label: '%{units} jednostka',
    units_label: '%{units} jednostki',
    current_availability: 'Bieżąca dostępność',
    future_availability: 'Dostępność w przyszłości',
    size: 'Rozmiar',
    sku: 'SKU',
    availability_date: 'Data dostępności',
    quantity: 'Ilość',
    availability_subject_to_change: 'Dostępność może ulec zmianie',
    future_availability_subject_to_change: '*Dostępność może ulec zmianie i obejmuje zapasy kontraktowe.',
    contract_inventory_availability: 'Dostępność zapasów kontraktowych**',
    contract_inventory_footer: '**Dostępność zapasów objętych umową zostanie zaktualizowana po złożeniu zamówienia.',
    yes: 'Tak',
    no: 'nie',
    copy_to_all_confirmation_message: 'Czy na pewno chcesz skopiować ten zakres rozmiarów do wszystkich poniższych stylów?',
    no_configurator_data: 'Brak danych konfiguratora',
    oops: 'Ups!',
    something_went_wrong: 'Coś poszło nie tak!',
    error_message: 'Nie martw się, skontaktowaliśmy się z pomocą techniczną, aby poinformować ich o tym błędzie!',
    customize_item: 'Dostosuj przedmiot',
    per_item_upgrades: 'Na rozszerzenie przedmiotu',
    number_of_items: 'Liczba przedmiotów',
    upgrades_total: 'Rozszerzenia łącznie',
    total_price_message: '* Całkowita cena zostanie obliczona w Twoim koszyku.',
    customizer_summary: 'Podsumowanie konfiguracji',
    save: 'Zapisz',
    field_required: '%{field} jest wymagane',
    review_configuration: 'Sprawdź konfigurację',
    review_configurations: 'Sprawdź konfiguracje',
    dismiss: 'Odrzuć',
    review: 'Sprawdź',
    cyrp_changed: 'Dostępność komponentów w skonfigurowanych produktach mogła ulec zmianie. Sprawdź konfiguracje produktu.',
    cyrp_review_warning: 'Dostępność komponentów w skonfigurowanych produktach mogła ulec zmianie. Sprawdź konfigurację produktu, aby kontynuować.',
    quantity_not_available: 'Ten komponent jest obecnie niedostępny w żądanej ilości. Wybierz alternatywny przedmiot, który jest w magazynie.',
    customized: 'Dostosowane',
    personalized: 'Spersonalizowane',
    customization: 'Dostosowanie',
    preview: 'Podgląd',
    preview_design: 'Podgląd projektu',
    copy_preview_url: 'Kopiuj adres URL podglądu',
    edit: 'Edytuj',
  }),
);
