define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojox/app/Controller",
  "scramble/widgets/modal/Alert",
  "dojo/i18n!scramble/nls/connectionMonitor"
], function(
  declare,
  lang,
  Controller,
  Alert,
  nls
) {

  return declare(Controller, {

    nls: nls,

    constructor: function(app, events) {
      this.alert = new Alert({
        style: {width: '300px'},
        title: this.nls.load_error,
        description: this.nls.description
      });

      require.on('error', lang.hitch(this, function(e) {
        if (e.message == 'timeout') {
          this.alert.show();
        }
      }));
    }
  });

});
