define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "scramble/models/customCatalog/Item",
  "dojox/mvc/StatefulArray",
  "dojo/when"
], function(
  declare,
  lang,
  array,
  Item,
  StatefulArray,
  when
  ) {

  let counter = 0;

  var Product = declare(Item, {
    type: 'Product',

    constructor: function() {
      this._fields = lang.mixin({}, this._fields, {
        productNumber: null,
        variationCodes: null
      });
      this.uniqueKeyFragment = counter++;
    },

    postscript: function(params) {
      lang.mixin(this, this._fields);
      if (params && params.variation_codes && !params.set) {
        this.unserialize(params);
      } else if (params && params.serialized) {
        this.unserialize(params);
        delete params.serialized;
        this.inherited(arguments);
      } else {
        this.inherited(arguments);
      }

      if (!this.get('variationCodes') || this.get('variationCodes').length === 0) {
        this.set('variationCodes', new StatefulArray());
      }

      setTimeout(lang.hitch(this, function() {
        this.watch(lang.hitch(this, '_onChange'));
      }), 10);
    },

    _productSetter: function(product) {
      this.product = product;
      this.productNumber = product.number;
    },

    _variationsGetter: function() {
      return array.filter(this.product.variations, function(variation) {
        return !this.variationCodes || array.indexOf(this.variationCodes, variation.code) != -1;
      }, this);
    },

    _onChange: function(name, oldValue, newValue) {
      this.onChange.apply(this, arguments);
    },

    onChange: function() {},

    serialize: function() {
      return lang.mixin(this.inherited(arguments), {
        product_number:  this.get('productNumber'),
        variation_codes: this.get('variationCodes')
      });
    },

    unserialize: function(serialized) {
      this.inherited(arguments);
      var obj = {
        productNumber:  serialized.product_number,
        variationCodes: new StatefulArray(serialized.variation_codes)
      };

      if (serialized.product) {
        obj.product = serialized.product;
      } else if (serialized.productCache) {
        obj.product = array.filter(serialized.productCache, function(product) {
          return product.number == obj.productNumber;
        })[0];
      }

      this.set(obj);
    },

    _uniqueKeyGetter: function() {
      return `custCatProduct:${this.productNumber}_${this.uniqueKeyFragment}`;
    }
  });

  Item.registerType('Product', Product);

  return Product;

});

