define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "scramble/models/Base"
], function(
  declare,
  lang,
  array,
  Base
) {

    var Item = declare(Base, {
      _fields: null,

      noType: false,

      constructor: function() {
        this._fields = {
          position: null
        };
      },

      serialize: function() {
        var ret = {
          position: this.get('position')
        };

        if (!this.noType) {
          ret._type = this.get('type');
        }

        return ret;
      },

      unserialize: function(serialized) {
        var type = serialized._type;

        if (!type) {
          type = "CustomCatalog::Product";
        }

        var parts = type.split('::');
        type = parts[parts.length-1];

        this.set({
          position: serialized.position,
          noType: serialized.noType,
          type:     type
        });
      },

      _uniqueKeyGetter: function() {
        throw new Error("not implemented");
      }
    });

    Item.types = {};

    Item.create = function(params) {
      var type = params._type;

      if (!type && params.product) {
        type = "CustomCatalog::Product";
        params.noType = true;
      }

      var parts = type.split('::');
      type = parts[parts.length-1];
      var ctor = Item.types[type];
      if (ctor) {
        return new ctor(params);
      }
    };

    Item.createFromData = function(data, type) {
      var ctor = Item.types[type];
      if (ctor) {
        return new ctor(data);
      }
    };

    Item.registerType = function(name, ctor) {
      Item.types[name] = ctor;
    };

    return Item;
});
