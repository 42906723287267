define([
  "dojo/has",
  "dojo/sniff"
], function (
  has,
  sniff
) {
  has.add("tablet", sniff("ios") || sniff("android"));
  return has;
});
