define(
  ({
    doc_type: 'Typ dokumentu',
    doc_date: 'Data dokumentu',
    doc_number: 'Numer dokumentu',
    ean_thirteen: 'EAN-13',
    supply_date: 'Data dostawy',
    discount: 'Rabat',
    sku: 'SKU',
    quantity_picked: 'Ilość odebrana',
    no_data_message: 'Brak wpisów',
    order_number: 'Nr zamówienia ERP',
    elastic_order_number: 'Nr zamówienia Elastic',
    line_number: 'Nr linii',
    customer_name: 'Nazwa klienta',
    customer_number: 'Klient nr',
    location_number: 'Lokalizacja nr',
    location_name: 'Nazwa lokalizacji',
    product: 'Nr produktu',
    style_name: 'Nazwa produktu',
    code: 'Kod koloru',
    color: 'Nazwa koloru',
    status: 'Status linii',
    states: 'Status zamówienia',
    tracking: 'Nr do śledzenia',
    parcel_number: 'Paczka nr',
    year: 'Rok',
    season: 'Sezon',
    order_date: 'Data utworzenia',
    cancel_date: 'Anuluj według daty',
    invoice_date: 'Data faktury',
    invoice: 'Faktura nr',
    invoice_url: 'URL faktury',
    download: 'Pobierz',
    view: 'Wyświetl',
    ship_date: 'Żądana data wysyłki',
    expected_ship_date: 'Szacowana data wysyłki',
    terms_name: 'Nazwa regulaminu',
    po_number: 'Nr zamówienia',
    size: 'Rozmiar',
    alt_name: 'Długość rozmiaru',
    upc: 'UPC',
    units: 'Ilość',
    unit_singular: 'Jednostka',
    unit_plural: 'Jednostki',
    amount: 'Ilość',
    net_price: 'Netto',
    unit_price: 'Cena jednostki',
    unit_retail_price: 'Cena detaliczna za jednostkę',
    orders: 'Zamówienia',
    totals: 'Łącznie',
    total: 'Łącznie',
    details: 'Szczegóły',
    open: 'Otwórz',
    shipped: 'Wysłano',
    cancelled: 'Anulowano',
    canceled: 'Anulowano',
    released: 'Wydany',
    invoiced: 'Zafakturowano',
    picked: 'Odebrany',
    region: 'Region',
    order_type: 'Typ zamówienia',
    backorder_date: 'Data zamówienia zaległego',
    backorder: 'Zamówienie zaległe',
    payment_terms: 'Regulamin płatności',
    ship_via: 'Wyślij przez',
    line_discount: 'Rabat liniowy',
    rma_number: 'RMA nr',
    cash_discount: 'Rabat gotówkowy',
    hold_for_confirmation: 'Wstrzymane do potwierdzenia',
    credit_hold: 'Wstrzymanie kredytu',
    order_notes: 'Notatki zamówienia',
    product_series: 'Seria produktu',
    product_category: 'Kategoria produktu',
    product_sub_category: 'Podkategoria produktu',
    quantity_confirmed: 'Potwierdzono',
    quantity_backordered: 'Zamówienie zaległe',
    quantity_cancelled: 'Anulowano',
    quantity_shipped: 'Wysłano',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Katalog',
    source: 'Źródło',
    division: 'Podział',
    actual_ship_date: 'Rzeczywista data wysyłki',
    rep_name: 'Nazwisko przedstawiciela',
    tracking_carrier: 'Kod przewoźnika',
    gross_amount: 'Kwota brutto',
    discount_percent: 'Rabat %',
    internal_code: 'Kod wewnętrzny',
    requested_date: 'Żądana data',
    net_amount_total: 'Łączna kwota netto',
    freight_amount: 'Kwota za transport',
    client_order_status: 'Status zamówienia',
    ship_address: 'Adres wysyłki',
    ship_city: 'Miasto wysyłki',
    ship_state: 'Stan wysyłki',
    warehouse_name: 'Nazwa magazynu',
    client_carrier_code: 'Kod przewoźnika klienta',
    discount_1: 'Rabat 1',
    discount_2: 'Rabat 2',
    discount_3: 'Rabat 3',
    brand: 'Marka',
    quantity_open: 'Otwarta ilość',
    amount_open: 'Otwarta kwota',
    quantity_invoiced: 'Ilość na fakturze',
    amount_invoiced: 'Kwota na fakturze',
    promo_code: 'Kod promocyjny',
    size_code: 'Kod rozmiaru',
    due_date: 'Termin',
    quantity_ordered: 'Zamówiona ilość',
    quantity_allocated: 'Przydzielona ilość',
    available_date: 'Dostępna data',
    bank_information: 'Informacje bankowe',
    shipment_number: 'Nr przesyłki',
    quantity_shipped_sku: 'Wysłana SKU',
    name: 'Nazwa',
    customs_reference: 'Numer referencyjny celny',
    currency_code: 'Kod waluty',
    id_model_partenaire_1: 'ID modelu partnera',
    id_model_partenaire_2: 'ID modelu partnera 2',
    expected_ship_date_range: 'Szacowany przedział dat wysyłki',
    hs_code: 'Kod HS',
  }),
);
