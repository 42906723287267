define(
  ({
    new_order_document: 'Elastic-rendelés',
    no_quantities: 'A kiválasztott mennyiségnek legalább 1-nek kell lennie a rendelés elküldéséhez.',
    no_customer_number: 'Kérjük, válassz Számlázási címet a következőhöz: ',
    no_location_number: 'Kérjük, válassz Szállítási címet a következőhöz: ',
    no_arrival_date: 'Kérjük, válaszd ki a Szállítás kezdési dátumát a következőhöz:',
    no_cancel_date: 'Kérjük, válassz Visszavonási dátumot a következőhöz:',
    invalid_cancel_date: 'Kérjük, érvényes Visszavonási dátumot válassz.',
    invalid_start_ship_date: 'Kérjük, érvényes Szállítás kezdési dátumát válassz.',
    no_po_number: 'Kérjük, add meg a PO-számot a következőhöz:',
    po_number_duplicate: 'A PO-számnak minden rendelés esetében egyedinek kell lennie.',
    invalid_po_number: 'Kérjük, érvényes PO-számot adj meg',
    invalid_page_comment: 'Kérjük, érvényes hozzászólást adj meg',
    products_not_available: 'Olyan termékek vannak a kosaradban, amelyek nem lesznek elérhetőek a kiválasztott szállítási dátumon.',
    already_submitted: 'Ez a rendelés már el lett küldve.',
    already_review: 'Már elküldted ezt a rendelést az üzletkötődnek, most ellenőrzésre vár.',
    shared_with: 'A dokumentum megosztásra került egy kereskedővel. Írásvédett és nem elküldhető.',
    dealer_submit: 'A dokumentum az üzletkötőd ellenőrzésére vár, és nem küldhető el',
    page: 'Oldal',
    shipment: 'Szállítás',
    external_field: 'Kérjük, válassz ### a következőhöz: ',
    credit_hold_explanation: 'A jelenlegi fiók állapotod szerint létrehozhatsz egy rendelést, de nem küldheted el. Kérjük, lépj kapcsolatba a hitelkezelőddel.',
    catalog_min_failure: 'Legalább ### egység kell ennél a katalógusnál.',
    catalog_max_failure: '### egység korlátja túllépve ennél a katalógusnál.',
    catalog_min_amount_failure: 'A minimális rendelési mennyiség ennél a katalógusnál ###.',
    catalog_max_amount_failure: 'A maximális rendelési mennyiség ennél a katalógusnál ###.',
    catalog_price_group_failure: 'A megrendelés nem adható le a hiányzó árcsoport miatt.',
    catalog_requires_embellishments: 'Minden tételnek személyre szabottnak kell lennie.',
    arrive_on_is_in_past: 'A kezdő szállítási dátum a múltban történt a következőhöz: ###. Kérjük, frissítsd a mai napra vagy egy jövőbeli dátumra.',
    shipping_account_number_invalid: 'Kérjük, érvényes szállítási számlaszámot adj meg',
    document_renamed: 'Rendelés átnevezve.',
    document_could_not_be_renamed: 'A megrendelést nem lehetett átnevezni.',
    has_placeholder_allocations: 'Néhány tétel nem áll rendelkezésre az általad kért szállítási időpontban. A folytatáshoz meg kell oldani a problémát.',
  }),
);
