define(
  ({
    new_order_document: 'Elastic-Auftrag',
    no_quantities: 'Geben Sie für die Anzahl des gewählten Artikels mindestens 1 an, um eine Bestellung aufzugeben.',
    no_customer_number: 'Bitte wählen Sie eine Rechnungsadresse für ',
    no_location_number: 'Bitte wählen Sie eine Versandadresse für ',
    no_arrival_date: 'Bitte wählen Sie ein Versandstartdatum für',
    no_cancel_date: 'Bitte wählen Sie einen Stornotermin für',
    invalid_cancel_date: 'Bitte wählen Sie ein gültiges Datum für die Stornierung.',
    invalid_start_ship_date: 'Bitte wählen Sie ein gültiges Startdatum für den Versand von',
    no_po_number: 'Bitte wählen Sie eine Auftragsnummer für',
    po_number_duplicate: 'Die Auftragsnummern müssen für jeden Auftrag einzigartig sein.',
    invalid_po_number: 'Bitte eine gültige Auftragsnummer eingeben',
    invalid_page_comment: 'Bitte einen gültigen Kommentar eingeben',
    products_not_available: 'In Ihrem Warenkorb befinden sich Artikel, die nicht bis zum ausgewählten Versanddatum verfügbar sein werden.',
    already_submitted: 'Dieser Auftrag wurde bereits übermittelt.',
    already_review: 'Sie haben diese Bestellung bereits bei Ihrem Vertreter eingereicht, sie muss überprüft werden.',
    shared_with: 'Das Dokument wurde für einen Händler freigegeben. Es kann nur gelesen, aber nicht übermittelt werden',
    dealer_submit: 'Das Dokument wartet auf die Überprüfung durch Ihren Vertreter und kann nicht übermittelt werden',
    page: 'Seite',
    shipment: 'Sendung',
    external_field: 'Bitte wählen Sie ### für ',
    credit_hold_explanation: 'Mit Ihrem aktuellen Kontostatus können Sie eine Bestellung erstellen, diese aber nicht absenden. Bitte kontaktieren Sie Ihren Guthabenverwalter.',
    catalog_min_failure: 'Muss mindestens ### Einheiten für diesen Katalog haben.',
    catalog_max_failure: 'Grenze von ### Einheiten für diesen Katalog überschritten.',
    catalog_min_amount_failure: 'Die Mindestbestellmenge für diesen Katalog beträgt ###.',
    catalog_max_amount_failure: 'Der maximale Gesamtbestellwert für diesen Katalog beträgt ###.',
    catalog_price_group_failure: 'Bestellung kann aufgrund einer fehlenden Preisgruppe nicht aufgegeben werden.',
    catalog_requires_embellishments: 'Alle Artikel müssen personalisiert sein.',
    arrive_on_is_in_past: 'Das Startversanddatum liegt ### in der Vergangenheit. Bitte auf heute oder Datum in der Zukunft aktualisieren.',
    shipping_account_number_invalid: 'Bitte geben Sie eine gültige Versandkontonummer ein',
    document_renamed: 'Auftrag umbenannt.',
    document_could_not_be_renamed: 'Auftrag konnte nicht umbenannt werden.',
    has_placeholder_allocations: 'Einige Artikel sind zu dem von Ihnen gewünschten Liefertermin nicht verfügbar. Um fortfahren zu können, muss dieses Problem gelöst werden.',
  }),
);
