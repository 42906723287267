define([
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/when',
  'dojo/date/stamp',
  'scramble/env',
  'scramble/stores/Catalog',
  'scramble/models/CustomCatalog',
  'scramble/models/Base',
], (
  declare,
  lang,
  array,
  when,
  stamp,
  env,
  CatalogStore,
  CustomCatalog,
  Base,
) => {
  const User = declare([Base], {
    _fields: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      type: 'dealer',
      password: '',
      passwordConfirmation: '',
      baseCatalogs: null,
      customCatalogs: null,
      customer: null,
      defaultCustomCatalogId: null,
      notes: [],
      ratings: [],
      groups: [],
      clientFields: {},
      catalogPermissions: null,
      campaigns: {},
      documentsDisabled: false,
      _id: null,
      acceptedPrivacyPolicy: false,
      isManager: false,
      associatedCustomers: null,
      searchRecommendations: [],
      brandAdmin: false,
    },

    catalogStore: new CatalogStore(),

    getAssociatedIds() {
      const userIds = [];
      if (this && this._id) {
        userIds.push(this._id);
      }

      if (this && this.customer && this.customer) {
        if (this.customer._id) {
          userIds.push(this.customer._id);
        }
        if (this.customer.number) {
          userIds.push(this.customer.number);
        }
      }

      return userIds;
    },

    canShare() {
      return true;
    },

    canOpenWith() {
      return this.type === 'rep';
    },

    _customers: null,
    getCustomers() {
      // memoize
      if (this._customers) return this._customers;

      const customerNumbers = new Set();
      const customers = [];

      this.get('groups').forEach((group) => {
        group.customers.forEach((customer) => {
          if (customerNumbers.has(customer.number)) return;

          customers.push(customer);
          customerNumbers.add(customer.number);
        });
      });

      if (
        this.get('customer')
        && this.get('type') != 'rep'
        && !customerNumbers.has(this.get('customer')).number
      ) {
        customers.unshift(this.get('customer'));
      }

      this._customers = customers;

      return customers;
    },

    getCustomer(number) {
      return array.filter(this.getCustomers(), (customer) => customer.number === number)[0];
    },

    getBaseCatalogs(includeArchived = false) {
      let result;
      if (this.type == 'dealer') {
        result = this.getCatalogsForCustomer(this.customer, includeArchived);
      } else {
        result = this._filterArchivedCatalogs(
          this.get('baseCatalogs'),
          includeArchived,
        );
      }

      return result;
    },

    _filterArchivedCatalogs(baseCatalogs, includeArchived = false) {
      return baseCatalogs
        .filter((baseCatalog) => includeArchived || !baseCatalog.archived)
        .filter((baseCatalog) => {
          const lastLive = baseCatalog && baseCatalog.dates && baseCatalog.dates.last_live;
          return (
            !lastLive
            || includeArchived
            || lastLive >= stamp.toISOString(new Date(), { selector: 'date' })
          );
        });
    },

    getCustomersForBaseCatalog(baseCatalogKey) {
      let baseCatalog;
      if (typeof baseCatalogKey === 'string') {
        baseCatalogs = this.getBaseCatalogs();
        const filteredCatalogs = array.filter(baseCatalogs, (item) => item.key == baseCatalogKey);
        baseCatalog = filteredCatalogs.pop();
      } else {
        baseCatalog = baseCatalogKey;
      }

      if (!baseCatalog) return;

      let perms = baseCatalog.permissions || [];
      if (perms.allow) {
        perms = perms.allow;
      } else if (!Array.isArray(perms)) {
        return false;
      }

      const customers = this.getCustomers();
      const catalogPerms = this.catalogPermissions;
      if (typeof catalogPerms === 'undefined' || catalogPerms === null) {
        return customers;
      }

      return array.filter(customers, (customer) => {
        const allowedCatalogs = catalogPerms[customer.number];
        return array.some(perms, (perm) => array.indexOf(allowedCatalogs, perm) !== -1);
      });
    },

    getCatalogsForCustomer(customer, includeArchived = false) {
      if (typeof customer === 'string') {
        const groupCustomer = this.getCustomer(customer);
        if (groupCustomer) {
          customer = groupCustomer;
        } else if (customer == this.customer.number) {
          customer = this.customer;
        }
      }

      if (!customer) {
        return [];
      }

      if (
        typeof this.catalogPermissions === 'undefined'
        || this.catalogPermissions === null
      ) {
        return this.get('baseCatalogs');
      }

      const allowedCatalogs = this.catalogPermissions[customer.number];

      if (!allowedCatalogs) {
        return [];
      }

      return when(
        this.get('baseCatalogs'),
        lang.hitch(this, function (baseCatalogs) {
          return this._filterArchivedCatalogs(
            array.filter(
              baseCatalogs,
              lang.hitch(this, (baseCatalog) => {
                if (!baseCatalog.permissions) {
                  return false;
                }

                let perms = baseCatalog.permissions;

                if (perms.allow) {
                  perms = perms.allow;
                } else if (!Array.isArray(perms)) {
                  return false;
                }

                return array.some(perms, (perm) => array.indexOf(allowedCatalogs, perm) !== -1);
              }),
            ),
            includeArchived,
          );
        }),
      );
    },

    getBaseCatalogForCustomCatalog(customCatalog) {
      let baseCatalogKey;

      if (typeof customCatalog === 'object') {
        baseCatalogKey = customCatalog.catalogKey || customCatalog.parent_catalog_key;
      } else {
        baseCatalogKey = customCatalog;
      }

      return array.filter(this.getBaseCatalogs(), (baseCatalog) => baseCatalog.key === baseCatalogKey)[0];
    },

    getCustomCatalogsForBaseCatalog(baseCatalog) {
      if (typeof baseCatalog === 'object') {
        baseCatalog = baseCatalog.key;
      }

      return array.filter(this.customCatalogs, (customCatalog) => (
        (customCatalog.catalogKey || customCatalog.parent_catalog_key)
          === baseCatalog
      ));
    },

    addCustomCatalogToList(customCatalog) {
      if (this.findCustomCatalog(customCatalog.id || customCatalog._id)) {
        return;
      }

      this.customCatalogs.push(customCatalog);
    },

    removeCustomCatalogFromList(customCatalog) {
      customCatalog = this.findCustomCatalog(
        customCatalog.id || customCatalog._id,
      );

      const idx = array.indexOf(this.customCatalogs, customCatalog);
      this.customCatalogs.splice(idx, 1);
    },

    findCustomCatalog(id) {
      return array.filter(this.customCatalogs, (customCatalog) => (customCatalog.id || customCatalog._id) == id)[0];
    },

    findBaseCatalog(key) {
      return array.filter(this.baseCatalogs, (catalog) => catalog.key == key)[0];
    },

    clearNote(variation, product) {
      const existing = this._findNote(variation, product, 'notes');
      if (!existing) {
        return null;
      }

      const index = array.indexOf(this.notes, existing);
      this.notes.splice(index, 1);
      return existing;
    },

    clearRating(variation, product, catalogKey) {
      const existing = this._findNote(variation, product, 'ratings', catalogKey);
      if (!existing) {
        return null;
      }

      const index = array.indexOf(this.ratings, existing);
      this.ratings.splice(index, 1);
      return existing;
    },

    setNote(variation, product, text) {
      code = variation == null ? null : variation.code;
      const note = {
        product_number: product.number,
        variation_code: code,
        text,
      };

      const existing = this._findNote(variation, product, 'notes');
      const index = array.indexOf(this.notes, existing);

      if (existing) {
        note._id = existing._id;
        this.notes.splice(index, 1, note);
      } else {
        this.notes.push(note);
      }

      return note;
    },

    setRating(variation, product, value, catalogKey) {
      const rating = {
        product_number: product.number,
        variation_code: variation.code,
        rating: value,
        catalog_key: catalogKey,
      };

      const existing = this._findNote(variation, product, 'ratings', catalogKey);
      const index = array.indexOf(this.ratings, existing);

      if (existing) {
        rating._id = existing._id;
        this.ratings.splice(index, 1, rating);
      } else {
        this.ratings.push(rating);
      }

      return rating;
    },

    customerCreditStatus(customer) {
      if (typeof customer === 'string') {
        customer = this.getCustomer(customer);
      }

      return (
        ((customer && customer.tags && customer.tags.credit_status) || []).join(
          ' ',
        ) || null
      );
    },

    getNote(variation, product, catalogKey) {
      return this._findNote(variation, product, 'notes', catalogKey);
    },

    getRating(variation, product, catalogKey) {
      return this._findNote(variation, product, 'ratings', catalogKey);
    },

    _findNote(variation, product, prop, catalogKey) {
      if (!prop) prop = 'notes';
      code = variation == null ? null : variation.code;
      return array.filter(this[prop], (item) => {
        const matched = item.product_number == product.number && item.variation_code == code;

        if (catalogKey && matched) {
          return item.catalog_key === catalogKey;
        }

        return matched;
      })[0];
    },

    postscript(params) {
      // if params is a serialized PrintJob
      if (params && params._id && !params.set) {
        this.unserialize(params);
        // if we were passed a serialized PrintJob
      } else if (params && params.serialized) {
        this.unserialize(params);
        delete params.serialized;
        this.inherited(arguments);
        // defaults all around
      } else {
        this.inherited(arguments);
      }

      if (!this.get('customCatalogs')) {
        this.set('customCatalogs', []);
      }

      if (!this.notes) {
        this.notes = [];
      }

      if (!this.ratings) {
        this.ratings = [];
      }

      setTimeout(
        lang.hitch(this, function () {
          this.watch(lang.hitch(this, '_onChange'));
        }),
        0,
      );
    },

    _customCatalogsSetter(customCatalogs) {
      this.customCatalogs = customCatalogs;
    },

    _nameGetter() {
      return `${this.get('firstName')} ${this.get('lastName')}`;
    },

    _onChange(name, oldValue, newValue) {
      this.onChange.apply(this, arguments);
    },

    onChange() {},

    serialize() {
      return {
        id: this.get('id'),
        _id: this.get('_id'),
        username: this.get('username'),
        first_name: this.get('firstName'),
        last_name: this.get('lastName'),
        email: this.get('email'),
        type: this.get('type'),
        password: this.get('password'),
        password_confirmation: this.get('passwordConfirmation'),
        customer: this.get('customer'),
        accepted_privacy_policy: this.get('acceptedPrivacyPolicy'),
        is_manager: this.get('isManager'),
        associated_customers: this.get('associatedCustomers'),
        brand_admin: this.get('brandAdmin'),
      };
    },

    _mapBaseCatalogs(catalog) {
      return catalog;
    },

    unserialize(serialized) {
      this.set({
        _id: serialized._id,
        id: serialized.id,
        externalId: serialized.external_id,
        type: serialized.type,
        firstName: serialized.first_name,
        lastName: serialized.last_name,
        password: serialized.password,
        passwordConfirmation: serialized.password_confirmation,
        username: serialized.username,
        email: serialized.email,
        customer: serialized.customer,
        dashboard: serialized.dashboard,
        dashboards: serialized.dashboards,
        notifications: serialized.notifications,
        notes: serialized.notes,
        ratings: serialized.ratings,
        tags: serialized.tags,
        baseCatalogs: array.map(
          serialized.catalogs,
          lang.hitch(this, '_mapBaseCatalogs'),
        ),
        catalogs: array.map(
          serialized.catalogs,
          lang.hitch(this, '_mapBaseCatalogs'),
        ),
        groups: serialized.groups,
        clientFields: serialized.client_fields,
        catalogPermissions: serialized.catalog_permissions,
        campaigns: serialized.campaigns,
        documentsDisabled: serialized.documents_disabled,
        customCatalogs: array.map(serialized.custom_catalogs, (cc) => new CustomCatalog(cc)),
        isManager: serialized.is_manager,
        associatedCustomers: serialized.associated_customers,
        activeVouchers: serialized.active_vouchers,
        customCatalogsOnly: serialized.custom_catalogs_only,
        origin: serialized.origin,
        searchRecommendations: serialized.search_recommendations,
        brandAdmin: serialized.brand_admin,
      });
    },
  });

  return User;
});
