import {
  createWrappedComponent,
  renderReactComponent,
  unmountReactComponentAtNode,
} from 'scramble/widgets/scramble-com-shim';

const create = ({ modalComponent, nls, wrap = (component) => component }) => {
  let promise = null;
  let resolvePromise = () => null;
  let rejectPromise = null;
  let component = null;
  let domNode = null;
  let setupDone = false;

  const setup = () => {
    component = wrap(createWrappedComponent(nls, modalComponent));
    domNode = document.createElement('div');
    setupDone = true;
  };

  const teardown = (resolution) => {
    if (promise?.isFulfilled() === false) {
      if (resolution) {
        resolvePromise(resolution);
      } else {
        rejectPromise('cancelled');
      }
    }

    promise = null;
    resolvePromise = null;
    rejectPromise = null;
  };

  const hide = (resolution) => {
    if (domNode) {
      unmountReactComponentAtNode(domNode);
      teardown(resolution);
    }
  };

  const show = (props = {}) => {
    promise = new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    if (!setupDone) setup();

    try {
      document.body.appendChild(domNode);

      renderReactComponent({
        component,
        domNode,
        props,
      });
    } catch (e) {
      rejectPromise(e);
      teardown();
    }

    return promise;
  };

  return {
    show,
    hide,
  };
};

export default create;
