define(
  ({
    new_order_document: 'Заказ Elastic',
    no_quantities: 'Для отправки заказа необходимо выбрать количество не менее 1.',
    no_customer_number: 'Выберите адрес выставления счетов для ',
    no_location_number: 'Выберите адрес доставки для ',
    no_arrival_date: 'Выберите дату отправки для',
    no_cancel_date: 'Выберите дату отмены для',
    invalid_cancel_date: 'Выберите допустимую дату отмены.',
    invalid_start_ship_date: 'Выберите допустимую дату отправки для',
    no_po_number: 'Введите номер заказа на поставку для',
    po_number_duplicate: 'Номера заказов на поставку должны быть уникальными для каждого заказа.',
    invalid_po_number: 'Введите допустимый номер заказа на поставку',
    invalid_page_comment: 'Введите допустимый комментарий',
    products_not_available: 'В вашей корзине есть товары, которые не будут в наличии к выбранной дате отправки.',
    already_submitted: 'Этот заказ уже был отправлен.',
    already_review: 'Вы уже отправили этот заказ своему представителю. Его необходимо проверить.',
    shared_with: 'Дилеру предоставлен доступ к документу. Он предназначен только для чтения и не может быть отправлен',
    dealer_submit: 'Документ ожидает проверки со стороны вашего представителя и не может быть отправлен',
    page: 'Страница',
    shipment: 'Доставка',
    external_field: 'Выберите ### для ',
    credit_hold_explanation: 'Текущий статус вашей учетной записи позволяет вам создавать заказы, но не отправлять их. Обратитесь к своему менеджеру по кредитам.',
    catalog_min_failure: 'Должно содержаться не менее ### позиций для данного каталога.',
    catalog_max_failure: 'Превышено ограничение в размере ### позиций для данного каталога.',
    catalog_min_amount_failure: 'Мин. общий объем заказа для данного каталога составляет ###.',
    catalog_max_amount_failure: 'Макс. общий объем заказа для данного каталога составляет ###.',
    catalog_price_group_failure: 'Невозможно разместить заказ из-за отсутствия ценовой группы.',
    catalog_requires_embellishments: 'Все позиции должны быть персональными.',
    arrive_on_is_in_past: 'Дата отправки для ### находится в прошлом. Измените ее на текущую или дату в будущем.',
    shipping_account_number_invalid: 'Введите допустимый номер учетной записи отправления.',
    document_renamed: 'Заказ переименован.',
    document_could_not_be_renamed: 'Не удалось переименовать заказ.',
    has_placeholder_allocations: 'Некоторые позиции недоступны к запрашиваемой дате отправки. Устраните ошибку, чтобы продолжить.',
  }),
);
