define([
  "dojo/_base/declare",
  "dojo/store/JsonRest",
  "dojo/request",
  "dojox/json/ref",
  "scramble/util/downloadFile",
  "scramble/env"
], function(
  declare,
  JsonRest,
  request,
  json,
  downloadFile,
  env
) {

  return declare(JsonRest, {
    target: env.get('apiTarget') + "catalogs/",

    downloadCsv: function(catalog, params){
      return downloadFile(this.target + '/' + (catalog.id || catalog._id) + '.csv', null, params);
    }
  });

});
