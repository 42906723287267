define([
  "dojo/_base/declare"
], function(
  declare
) {

  return declare(null, {
    parseOnLoad: false,

    // template to be loaded into containerNode as content of Dialog
    contentTemplate: null,

    _beforeFillContent: function() {
      if (!this.contentTemplate) {
        return this.inherited(arguments);
      }

      this.set('content', this._stringRepl(this.contentTemplate));
      this._attachTemplateNodes(this.containerNode, function(n,p){ return n.getAttribute(p); });
      this.inherited(arguments);
      this._afterFillContent = true;
    },

    _fillContent: function() {
      if (!this.contentTemplate) {
        return this.inherited(arguments);
      }
    },

    _setContent: function() {
      if (this._afterFillContent) {
        return;
      }

      this.inherited(arguments);
    }

  });

});
