define(['scramble/env'], function(env) {
  var missing;
  var missingLarge;

  function findProductImage(productOrVariationOrImage, style) {
    findMissing();

    if (!productOrVariationOrImage) {
      return getMissing();
    }

    if (isString(productOrVariationOrImage)) {
      return productOrVariationOrImage;
    }

    if (isArray(productOrVariationOrImage)) {
      return heroFromArray(productOrVariationOrImage);
    }

    if (isVariation(productOrVariationOrImage)) {
      return heroFromVariation(productOrVariationOrImage);
    }

    if (isProduct(productOrVariationOrImage)) {
      return heroFromProduct(productOrVariationOrImage);
    }

    if (isObject(productOrVariationOrImage)) {
      return styleFromImage(productOrVariationOrImage);
    }

    function getMissing() {
      return style == 'icon' || !style ? missing : missingLarge;
    }

    function heroFromProduct(product) {
      return product.variations[0] ?
        heroFromVariation(product.variations[0]) :
        getMissing();
    }

    function heroFromVariation(variation) {
      if (variation.images instanceof Array) {
        return heroFromArray(variation.images);
      } else if (variation.images && typeof variation.images == 'object') {
        return heroFromObject(variation.images);
      } else {
        return getMissing();
      }
    }

    function heroFromObject(o) {
      return o.P ? heroFromArray(o.P) : getMissing();
    }

    function heroFromArray(array) {
      return array[0] ? styleFromImage(array[0]) : getMissing();
    }

    function styleFromImage(image) {
      if (!style) {
        style = 'icon';
      }
      return image[style] ? wrapStyle(image[style]) : getMissing();
    }

    function isProduct(obj) {
      return obj.variations;
    }

    function isVariation(obj) {
      return !obj.variations && obj.images;
    }

    function isArray(obj) {
      return (obj instanceof Array);
    }

    function isObject(obj) {
      return (typeof obj == "object");
    }

    function isString(obj) {
      return (typeof obj == "string");
    }

    function wrapStyle(style) {
      if (style && style.match(/^\w+:\/\//i)) {
        return style;
      }

      return env.get('apiTarget') + 'product_images/' + style;
    }
  };

  function findMissing() {
    missing = require.toUrl(env.getFlag('missingImage'));
    missingLarge = require.toUrl(env.getFlag('missingImage'));

    findProductImage.missing = missing;
    findProductImage.missingLarge = missingLarge;
  }

  findProductImage.getMissing = findMissing;

  return findProductImage;

});
