define([
  'dojo/i18n!./nls/common',
  'dojo/topic',
  'scramble-com/ui/public/invalid-session-notification-modal',
  './widgets/ScrambleComModal',
  'scramble/util/session',
  './env',
], (
  nls,
  topic,
  { default: InvalidSessioneNotificationModal },
  { default: createScrambleComModal },
  { logout },
  env,
) => {
  const invalidSessionNotificationModal = createScrambleComModal({
    modalComponent: InvalidSessioneNotificationModal,
    nls,
  });

  const handleInvalidSession = () => {
    const isBuilder = env.get('document', false);

    invalidSessionNotificationModal.show({
      isOpen: true,
      downloadOrderJSON: isBuilder
        ? () => topic.publish('/scramble/builder/dumpDocument')
        : undefined,
      logout,
    });
  };

  env.watch('isSessionValid', () => {
    if (!env.isSessionValid) handleInvalidSession();
  });
});
