define(
  ({
    doc_type: 'Document Type',
    discount: 'Discount',
    sku: 'SKU',
    no_data_message: 'No Records',
    order_number: 'Order Number',
    elastic_order_number: 'Elastic Order Number',
    line_number: 'Line #',
    customer_name: 'Customer Name',
    customer_number: 'Customer Number',
    location_number: 'Location Number',
    product: 'Product Number',
    style_name: 'Product Name',
    code: 'colour Code',
    color: 'Colour',
    status: 'Status',
    tracking: 'Tracking',
    year: 'Year',
    season: 'Season',
    order_date: 'Order Date',
    cancel_date: 'Cancel Date',
    invoice_date: 'Invoice Date',
    invoice: 'Invoice',
    invoice_url: 'Invoice URL',
    download: 'Download',
    view: 'View',
    ship_date: 'Ship Date',
    terms_name: 'Terms Name',
    po_number: 'PO Number',
    size: 'Size',
    alt_name: 'Size Length',
    upc: 'UPC',
    units: 'Units',
    unit_singular: 'Unit',
    unit_plural: 'Units',
    amount: 'Amount',
    net_price: 'Net',
    unit_price: 'Unit',
    orders: 'Orders',
    totals: 'Totals',
    total: 'Total',
    details: 'Details',
    open: 'Open',
    shipped: 'Shipped',
    cancelled: 'Cancelled',
    canceled: 'Cancelled',
    released: 'Released',
    invoiced: 'Invoiced',
    picked: 'Picked',
    region: 'Region',
    order_type: 'Order Type',
    ship_via: 'Ship Via',
  }),
);
