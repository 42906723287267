define(
  ({
    doc_type: 'Typ dokumentu',
    doc_date: 'Datum dokumentu',
    doc_number: 'Číslo dokumentu',
    ean_thirteen: 'EAN-13',
    supply_date: 'Datum dodání',
    discount: 'Sleva',
    sku: 'SKU',
    quantity_picked: 'Vyzvednuté množství',
    no_data_message: 'Žádné záznamy',
    order_number: 'Objednací číslo ERP',
    elastic_order_number: 'Č. objednávky Elastic',
    line_number: 'Řádek č.',
    customer_name: 'Jméno zákazníka',
    customer_number: 'Č. zákazníka',
    location_number: 'Poloha #',
    location_name: 'Název umístění',
    product: 'Č. produktu',
    style_name: 'Název produktu',
    code: 'Barevný kód',
    color: 'Název barvy',
    status: 'Stav linky',
    states: 'Stav objednávky',
    tracking: 'Sledovací č.',
    parcel_number: 'Číslo balíčku',
    year: 'Rok',
    season: 'Sezóna',
    order_date: 'Datum vytvoření',
    cancel_date: 'Zrušit podle data',
    invoice_date: 'Datum faktury',
    invoice: 'Č. faktury',
    invoice_url: 'URL faktury',
    download: 'Stáhnout',
    view: 'Zobrazení',
    ship_date: 'Požadované datum expedice',
    expected_ship_date: 'Požadované datum expedice',
    terms_name: 'Název pojmů',
    po_number: 'Č. obj.',
    size: 'Velikost',
    alt_name: 'Délka',
    upc: 'UPC',
    units: 'Množství',
    unit_singular: 'Jednotka',
    unit_plural: 'Jednotky',
    amount: 'Množství',
    net_price: 'Síť',
    unit_price: 'Jednotková cena',
    unit_retail_price: 'Jednotková maloobchodní cena',
    orders: 'Objednávky',
    totals: 'Celkem',
    total: 'Celkem',
    details: 'Podrobnosti',
    open: 'Otevřeno',
    shipped: 'Odesláno',
    cancelled: 'Zrušeno',
    canceled: 'Zrušeno',
    released: 'Vydáno',
    invoiced: 'Fakturováno',
    picked: 'Vyzvednuto',
    region: 'Oblast',
    order_type: 'Typ objednávky',
    backorder_date: 'Datum doobjednávky',
    backorder: 'Doobjednávka',
    payment_terms: 'Platební podmínky',
    ship_via: 'Způsob dopravy',
    line_discount: 'Řádková sleva',
    rma_number: 'Č. RMA',
    cash_discount: 'Platební sleva',
    hold_for_confirmation: 'Podržte pro potvrzení',
    credit_hold: 'Zablokovaný úvěr',
    order_notes: 'Poznámky k objednávce',
    product_series: 'Produktové řady',
    product_category: 'produktová kategorie',
    product_sub_category: 'Podkategorie produktu',
    quantity_confirmed: 'Potvrzeno',
    quantity_backordered: 'Doobjednáno',
    quantity_cancelled: 'Zrušeno',
    quantity_shipped: 'Odesláno',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Katalog',
    source: 'Zdroj',
    division: 'Dělení',
    actual_ship_date: 'Skutečné datum dodání',
    rep_name: 'Jméno zástupce',
    tracking_carrier: 'Kód přepravce',
    gross_amount: 'Hrubá částka',
    discount_percent: 'Sleva %',
    internal_code: 'Interní kód',
    requested_date: 'Požadované datum',
    net_amount_total: 'Celková částka netto',
    freight_amount: 'Částka za přepravu',
    client_order_status: 'Stav objednávky',
    ship_address: 'Doručovací adresa',
    ship_city: 'Město doručení',
    ship_state: 'Stav doručení',
    warehouse_name: 'Název skladu',
    client_carrier_code: 'Kód přepravce klienta',
    discount_1: 'Sleva 1',
    discount_2: 'Sleva 2',
    discount_3: 'Sleva 3',
    brand: 'Značka',
    quantity_open: 'Otevřené množství',
    amount_open: 'Otevřená částka',
    quantity_invoiced: 'Fakturované množství',
    amount_invoiced: 'Fakturovaná částka',
    promo_code: 'Promo kód',
    size_code: 'Kód velikosti',
    due_date: 'Termín',
    quantity_ordered: 'Objednané množství',
    quantity_allocated: 'Alokované množství',
    available_date: 'Dostupné datum',
    bank_information: 'Informace o bance',
    shipment_number: 'Zásilka #',
    quantity_shipped_sku: 'Odesláno SKU',
    name: 'Název',
    customs_reference: 'Odkaz na celní úřad',
    currency_code: 'Kód měny',
    id_model_partenaire_1: 'ID partnerského modelu',
    id_model_partenaire_2: 'ID partnerského modelu 2',
    expected_ship_date_range: 'Předpokládané rozmezí data expedice',
    hs_code: 'Kód HS',
  }),
);
