define([
  'dojo/_base/declare',
  'scramble/env'
], function(declare, env) {

  var _SharingCommon = declare([], {
    'isDuplicatedFor':  isDuplicatedFor,
    'isCopiedTo':       isCopiedTo,
    'isSharedTo':       isSharedTo,
    'isAuthor':         isAuthor,
    '_getField':        _getField
  });

  // Static helpers
  _SharingCommon.isDuplicatedFor = isDuplicatedFor;
  _SharingCommon.isCopiedTo      = isCopiedTo;
  _SharingCommon.isSharedTo      = isSharedTo;
  _SharingCommon.isAuthor        = isAuthor;
  _SharingCommon._getField       = _getField;

  return _SharingCommon;

  /**
   * isCopiedTo
   * @param ids (optional) [string/array] defaults to current users identifiers
   */
  function isCopiedTo(ids) {
    return _checkSharingIds(_getField(this, 'copiedTo', []), ids ? _arrayify(ids) : _getCurrentUserIds());
  }

  /**
   * isDuplicatedFor
   * @param ids (optional) [string/array] defaults to current users identifiers
   */
  function isDuplicatedFor(ids) {
    return _checkSharingIds(_getField(this, 'duplicatedFor', []), ids ? _arrayify(ids) : _getCurrentUserIds());
  }

  /**
   * isSharedTo
   * @param ids (optional) [string/array] defaults to current users identifiers
   */
  function isSharedTo(ids) {
    return _checkSharingIds(_getField(this, 'sharedTo', []), ids ? _arrayify(ids) : _getCurrentUserIds());
  }

  /**
   * Checks for matching this.author_id given @ids (or defaults to user identifiers)
   * @param ids (optional) [string/array] defaults to current users identifiers
   */
  function isAuthor(ids) {
    return (ids ? _arrayify(ids) : _getCurrentUserIds()).indexOf(_getField(this, 'authorId', [])) > -1;
  }

  function _getField(ctx, name, defaultValue) {
    return (ctx && ctx.get) // Check if this is a Stateful thing
      ? (ctx.get(name) || defaultValue) // Check if the camelCased property exists
      : (ctx[name] || ctx[toSnakeCase(name)] || defaultValue);
  }

  function toSnakeCase(string) {
    return string.replace(/(.)([A-Z]+)/g, function(m, previous, uppers) {
      return previous + '_' + uppers.toLowerCase().split('').join('_')
    })
  }

  /**
   * Helper method, gets current users identifiers - user._id, customer.number, etc
   * @private
   */
  function _getCurrentUserIds() {
    if (env.user && env.user.getAssociatedIds) { return env.user.getAssociatedIds(); }
    return ['err: no valid user or unexpected model found']; // just so deep debugging isn't misery
  }

  /**
   * XOR of given ID arrays
   * @private
   */
  function _checkSharingIds(currentUserIds, sharedWithIds) {
    return currentUserIds.some(function(id) {
      return sharedWithIds.indexOf(id) > -1;
    });
  }

  /**
   * Obvi
   */
  function _arrayify(arr) {
    return Array.isArray(arr) ? arr : [arr];
  }

});
