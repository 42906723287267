define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "scramble/models/customCatalog/Item",
  "dojox/mvc/StatefulArray",
  "dojo/when"
], function(
  declare,
  lang,
  array,
  Item,
  StatefulArray,
  when
  ) {

  let pageBreakIdx = 0;

  var PageBreak = declare(Item, {
    type: 'PageBreak',

    constructor: function() {
      this._fields = lang.mixin({}, this._fields, {
        asset: null,
        title: null
      });
    },

    postscript: function(params) {
      lang.mixin(this, this._fields);
      if (params && params._id && !params.set) {
        this.unserialize(params);
      } else if (params && params.serialized) {
        this.unserialize(params);
        delete params.serialized;
        this.inherited(arguments);
      } else {
        this.inherited(arguments);
      }

      this.idx = pageBreakIdx++;

      setTimeout(lang.hitch(this, function() {
        this.watch(lang.hitch(this, '_onChange'));
      }), 10);
    },

    _onChange: function(name, oldValue, newValue) {
      this.onChange.apply(this, arguments);
    },

    onChange: function() {},

    serialize: function() {
      return lang.mixin(this.inherited(arguments), {
      });
    },

    unserialize: function(serialized) {
      this.inherited(arguments);
      var obj = {
        asset: {
          isTool: true,
          name: "Page Break",
          type: "PageBreak",
        }
      };

      this.set(obj);
    },

    _uniqueKeyGetter: function() {
      return "custCatPageBreak:" + this.idx;
    }
  });

  Item.registerType('PageBreak', PageBreak);

  return PageBreak;

});

