define(
  ({
    doc_type: 'Тип документа',
    doc_date: 'Дата документа',
    doc_number: 'Номер документа',
    ean_thirteen: 'EAN-13',
    supply_date: 'Дата поставки',
    discount: 'Скидка',
    sku: 'SKU',
    quantity_picked: 'Количество товара получено',
    no_data_message: 'Нет записей',
    order_number: 'Заказ №',
    elastic_order_number: 'Заказ Elastic #',
    line_number: 'Строка №',
    customer_name: 'Имя клиента',
    customer_number: 'Клиент №',
    location_number: 'Место №',
    location_name: 'Название места',
    product: 'Товар №',
    style_name: 'Название товара',
    code: 'Код цвета',
    color: 'Наименование цвета',
    status: 'Статус строки',
    states: 'Статус заказа',
    tracking: 'Номер отслеживания',
    parcel_number: 'Отправление №',
    year: 'Год',
    season: 'Время года',
    order_date: 'Дата создания',
    cancel_date: 'Отмена по дате',
    invoice_date: 'Дата счета',
    invoice: 'Счет №',
    invoice_url: 'URL счета',
    download: 'Загрузить',
    view: 'Просмотр',
    ship_date: 'Запрошенная дата доставки',
    expected_ship_date: 'Ожидаемая дата отправки',
    terms_name: 'Название условий',
    po_number: 'Заказ на поставку №',
    size: 'Размер',
    alt_name: 'Длина размера',
    upc: 'UPC',
    units: 'Кол-во',
    unit_singular: 'ед.',
    unit_plural: 'ед.',
    amount: 'Сумма',
    net_price: 'Итого',
    unit_price: 'Цена за единицу',
    unit_retail_price: 'Розничная цена за единицу',
    orders: 'Заказы',
    totals: 'Итого',
    total: 'Итого',
    details: 'Подробности',
    open: 'Открыть',
    shipped: 'Отправлено',
    cancelled: 'Отменено',
    canceled: 'Отменено',
    released: 'Выпущено',
    invoiced: 'Счет выставлен',
    picked: 'Товар получен',
    region: 'Регион',
    order_type: 'Тип заказа',
    backorder_date: 'Дата задолженного заказа',
    backorder: 'Задолженный заказ',
    payment_terms: 'Условия оплаты',
    ship_via: 'Отправка через',
    line_discount: 'Скидка по строке',
    rma_number: 'Разрешение на возврат товаров №',
    cash_discount: 'Скидка за наличный расчет',
    hold_for_confirmation: 'Приостановить для подтверждения',
    credit_hold: 'Приостановка кредита',
    order_notes: 'Примечания к заказу',
    product_series: 'Серия товара',
    product_category: 'Категория товара',
    product_sub_category: 'Подкатегория товара',
    quantity_confirmed: 'Подтверждено',
    quantity_backordered: 'Задолженный заказ',
    quantity_cancelled: 'Отменено',
    quantity_shipped: 'Отправленный',
    ivendix_order_id: 'ИД iVendix',
    catalog: 'Каталог',
    source: 'Источник',
    division: 'Подразделение',
    actual_ship_date: 'Фактическая дата отправки',
    rep_name: 'Имя представителя',
    tracking_carrier: 'Код службы доставки',
    gross_amount: 'Общая сумма',
    discount_percent: 'Скидка %',
    internal_code: 'Внутренний код',
    requested_date: 'Дата запроса',
    net_amount_total: 'Итоговая чистая сумма',
    freight_amount: 'Стоимость перевозки',
    client_order_status: 'Статус заказа',
    ship_address: 'Адрес доставки',
    ship_city: 'Город доставки',
    ship_state: 'Штат доставки',
    warehouse_name: 'Название склада',
    client_carrier_code: 'Код перевозчика клиента',
    discount_1: 'Скидка 1',
    discount_2: 'Скидка 2',
    discount_3: 'Скидка 3',
    brand: 'Бренд',
    quantity_open: 'Открытое количество',
    amount_open: 'Открытая сумма',
    quantity_invoiced: 'Количество по счету',
    amount_invoiced: 'Сумма по счету',
    promo_code: 'Промокод',
    size_code: 'Код размера',
    due_date: 'Срок действия',
    quantity_ordered: 'Заказанное количество',
    quantity_allocated: 'Выделенное количество',
    available_date: 'Доступная дата',
    bank_information: 'Банковские реквизиты',
    shipment_number: 'Отправление #',
    quantity_shipped_sku: 'Доставленный SKU',
    name: 'Имя',
    customs_reference: 'Ссылка для таможни',
    currency_code: 'Код валюты',
    id_model_partenaire_1: 'ИД партнерской модели',
    id_model_partenaire_2: 'ИД партнерской модели 2',
    expected_ship_date_range: 'Предполагаемый период отправки',
    hs_code: 'Код ТН ВЭД',
  }),
);
