define(
  ({
    doc_type: 'Tipo de documento',
    doc_date: 'Data do documento',
    doc_number: 'Número do documento',
    ean_thirteen: 'EAN-13',
    supply_date: 'Data de fornecimento',
    discount: 'Desconto',
    sku: 'SKU',
    quantity_picked: 'Quantidade recolhida',
    no_data_message: 'Sem registos',
    order_number: 'Encomenda N.º',
    elastic_order_number: 'Encomenda Elastic N.º',
    line_number: 'Linha N.º',
    customer_name: 'Nome do cliente',
    customer_number: 'Cliente N.º',
    location_number: 'Localização N.º',
    location_name: 'Nome da localização',
    product: 'Produto N.º',
    style_name: 'Nome do produto',
    code: 'Código de cor',
    color: 'Nome da cor',
    status: 'Estado da linha',
    states: 'Estado da encomenda',
    tracking: 'Número de seguimento',
    parcel_number: 'Parcela N.º',
    year: 'Ano',
    season: 'Época',
    order_date: 'Data de criação',
    cancel_date: 'Cancelado na data',
    invoice_date: 'Data da fatura',
    invoice: 'Fatura N.º',
    invoice_url: 'URL da fatura',
    download: 'Transferir',
    view: 'Ver',
    ship_date: 'Data de envio pedida',
    expected_ship_date: 'Data estimada para envio',
    terms_name: 'Nome dos termos',
    po_number: 'N.º de caixa postal',
    size: 'Tamanho',
    alt_name: 'Comprimento',
    upc: 'UPC',
    units: 'Qntd.',
    unit_singular: 'Unidade',
    unit_plural: 'Unidades',
    amount: 'Quantia',
    net_price: 'Líquido',
    unit_price: 'Preço unitário',
    unit_retail_price: 'Preço de retalho por unidade',
    orders: 'Encomendas',
    totals: 'Totais',
    total: 'Total',
    details: 'Detalhes',
    open: 'Abrir',
    shipped: 'Enviado',
    cancelled: 'Cancelado',
    canceled: 'Cancelado',
    released: 'Lançado',
    invoiced: 'Faturado',
    picked: 'Recolhido',
    region: 'Região',
    order_type: 'Tipo de encomenda',
    backorder_date: 'Data do atraso',
    backorder: 'Atraso',
    payment_terms: 'Termos de pagamento',
    ship_via: 'Enviar por',
    line_discount: 'Desconto de linha',
    rma_number: 'RMA N.º',
    cash_discount: 'Desconto em dinheiro',
    hold_for_confirmation: 'Aguarde por confirmação',
    credit_hold: 'Reserva de crédito',
    order_notes: 'Notas da encomenda',
    product_series: 'Série do produto',
    product_category: 'Categoria do produto',
    product_sub_category: 'Subcategoria do produto',
    quantity_confirmed: 'Confirmado',
    quantity_backordered: 'Atrasada',
    quantity_cancelled: 'Cancelado',
    quantity_shipped: 'Enviado',
    ivendix_order_id: 'ID iVendix',
    catalog: 'Catálogo',
    source: 'Origem',
    division: 'Divisão',
    actual_ship_date: 'Data real de envio',
    rep_name: 'Nome do rep.',
    tracking_carrier: 'Código da transportadora',
    gross_amount: 'Montante ilíquido',
    discount_percent: '% de desconto',
    internal_code: 'Código interno',
    requested_date: 'Dado do pedido',
    net_amount_total: 'Total do valor líquido',
    freight_amount: 'Valor da carga',
    client_order_status: 'Estado da encomenda',
    ship_address: 'Morada para envio',
    ship_city: 'Cidade para envio',
    ship_state: 'Estado para envio',
    warehouse_name: 'Nome do armazém',
    client_carrier_code: 'Código da transportadora para o cliente',
    discount_1: 'Desconto 1',
    discount_2: 'Desconto 2',
    discount_3: 'Desconto 3',
    brand: 'Marca',
    quantity_open: 'Quantidade aberta',
    amount_open: 'Montante aberto',
    quantity_invoiced: 'Quantidade faturada',
    amount_invoiced: 'Montante faturada',
    promo_code: 'Código promocional',
    size_code: 'Código de tamanho',
    due_date: 'Data de pagamento',
    quantity_ordered: 'Quantidade encomendada',
    quantity_allocated: 'Quantidade atribuída',
    available_date: 'Data disponível',
    bank_information: 'Informação do banco',
    shipment_number: 'Envio N.º',
    quantity_shipped_sku: 'SKU de envio',
    name: 'Nome',
    customs_reference: 'Referência de alfândega',
    currency_code: 'Código de moeda',
    id_model_partenaire_1: 'ID de modelo de parceiro',
    id_model_partenaire_2: 'ID de modelo de parceiro 2',
    expected_ship_date_range: 'Intervalo esperado para a data de envio',
    hs_code: 'Código HS',
  }),
);
