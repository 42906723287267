define(
  ({
    new_order_document: 'Pořadí Elastic',
    no_quantities: 'K odeslání objednávky musí být vybrané množství alespoň 1.',
    no_customer_number: 'Vyberte prosím fakturační adresu pro ',
    no_location_number: 'Vyberte prosím dodací adresu pro ',
    no_arrival_date: 'Vyberte počáteční datum dodání pro',
    no_cancel_date: 'Vyberte prosím datum zrušení',
    invalid_cancel_date: 'Vyberte prosím platné datum zrušení.',
    invalid_start_ship_date: 'Vyberte platné počáteční datum dodání pro',
    no_po_number: 'Zadejte prosím č. obj. pro',
    po_number_duplicate: 'Čísla objednávek musí být pro každou objednávku jedinečná.',
    invalid_po_number: 'Zadejte prosím platné č. obj.',
    invalid_page_comment: 'Zadejte platný komentář',
    products_not_available: 'Máte v košíku produkty, které nebudou k dispozici do vybraného data dodání.',
    already_submitted: 'Tato objednávka již byla odeslána.',
    already_review: 'Tuto objednávku jste již odeslali svému zástupci, je třeba ji zkontrolovat.',
    shared_with: 'Dokument byl sdílen s dealerem. Je pouze ke čtení a nelze jej odeslat',
    dealer_submit: 'Dokument čeká na kontrolu vaším zástupcem a nelze jej odeslat',
    page: 'Stránka',
    shipment: 'Zásilka',
    external_field: 'Vyberte prosím ### pro ',
    credit_hold_explanation: 'S aktuálním stavem svého účtu můžete vytvořit objednávku, ale nebudete ji moci odeslat. Obraťte se prosím na svého úvěrového manažera.',
    catalog_min_failure: 'V tomto katalogu musí být alespoň ### jednotek.',
    catalog_max_failure: 'Překročen limit ### jednotek pro tento katalog.',
    catalog_min_amount_failure: 'Minimální celková částka objednávky pro tento katalog je ###.',
    catalog_max_amount_failure: 'Maximální celková částka objednávky pro tento katalog je ###.',
    catalog_price_group_failure: 'Z důvodu chybějící cenové skupiny nelze zadat objednávku.',
    catalog_requires_embellishments: 'Všechny položky musí být personalizované.',
    arrive_on_is_in_past: 'Datum zahájení dodávky je u ### v minulosti. Aktualizujte to prosím na dnešní či budoucí datum.',
    shipping_account_number_invalid: 'Zadejte prosím platné číslo přepravního účtu',
    document_renamed: 'Objednávka byla přejmenována.',
    document_could_not_be_renamed: 'Objednávku nelze přejmenovat.',
    has_placeholder_allocations: 'Některé položky nejsou k dispozici k požadovanému datu odeslání. Abyste mohli pokračovat, je nutné tento problém vyřešit.',
  }),
);
