define([
  "dojo/_base/declare",
  "dojox/app/Controller",
  "scramble/env"
], function(
  declare,
  Controller,
  env
) {

  return declare(Controller, {
    constructor: function() {
      if (env.getFlag('externalSupport.provider') !== 'zendesk') return;
      const subdomain =  env.getFlag('externalSupport.subdomain');
      if (subdomain){
        window.zESettings = {
          webWidget: {
            offset: {
              horizontal: '175px',
              mobile: {
                horizontal: '175px',
              },
            },
            color: {
              theme: env.getFlag('styles.primary', '#000'),
            },
          },
        };
        window.zEmbed ||
        function(e,t){
          var n,o,d,i,s,a=[],r=document.createElement("iframe");
          window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;
          try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}
          o.open()._l=function(){var e=this.createElement("script");
          n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost=`${subdomain}.zendesk.com`,this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()
        }();
      }
    }
  });

});
