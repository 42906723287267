define(
  ({
    doc_type: 'Dokumenttyp',
    doc_date: 'Dokumentdatum',
    doc_number: 'Dokumentnummer',
    ean_thirteen: 'EAN-13',
    supply_date: 'Lieferdatum',
    discount: 'Rabatt',
    sku: 'SKU',
    quantity_picked: 'Menge Ausgewählt',
    no_data_message: 'Keine Datensätze',
    order_number: 'Auftragsnr.',
    elastic_order_number: 'Elastic-Auftragsnr.',
    line_number: 'Zeilennummer',
    customer_name: 'Kundenname',
    customer_number: 'Kundennr.',
    location_number: 'Standortnr.',
    location_name: 'Standortname',
    product: 'Produktnr.',
    style_name: 'Produktname',
    code: 'Farbcode',
    color: 'Farbname',
    status: 'Zeilenstatus',
    states: 'Auftragsstatus',
    tracking: 'Trackingnr.',
    parcel_number: 'Paket#',
    year: 'Jahr',
    season: 'Saison',
    order_date: 'Erstellungsdatum',
    cancel_date: 'Abbrechen nach Datum',
    invoice_date: 'Rechnungsdatum',
    invoice: 'Rechnungsnr.',
    invoice_url: 'Rechnungs-URL',
    download: 'Herunterladen',
    view: 'Anzeigen',
    ship_date: 'Gewünschtes Versanddatum',
    expected_ship_date: 'Voraussichtliches Versanddatum',
    terms_name: 'Zahlungsbedingung',
    po_number: 'Kaufauftragsnr.',
    size: 'Größe',
    alt_name: 'Länge',
    upc: 'UPC',
    units: 'Menge',
    unit_singular: 'Stück',
    unit_plural: 'Stücke',
    amount: 'Betrag',
    net_price: 'Netto',
    unit_price: 'Einheitspreis',
    unit_retail_price: 'Einzelhandelspreis pro Einheit',
    orders: 'Aufträge',
    totals: 'Auftragssumme',
    total: 'Summe',
    details: 'Details',
    open: 'Öffnen',
    shipped: 'Versendet',
    cancelled: 'Abgebrochen',
    canceled: 'Abgebrochen',
    released: 'Freigegeben',
    invoiced: 'In Rechnung gestellt',
    picked: 'Ausgewählt',
    region: 'Region',
    order_type: 'Auftragsart',
    backorder_date: 'Rückstandsdatum',
    backorder: 'Rückstand',
    payment_terms: 'Zahlungsbedingungen',
    ship_via: 'Versenden mit',
    line_discount: 'Positionsrabatt',
    rma_number: 'Rücksendenr.',
    cash_discount: 'Barrabatt',
    hold_for_confirmation: 'Warten auf Bestätigung',
    credit_hold: 'Zurückhalten',
    order_notes: 'Bestellhinweise',
    product_series: 'Produktreihen',
    product_category: 'Produktkategorie',
    product_sub_category: 'Produktunterkategorie',
    quantity_confirmed: 'Bestätigt',
    quantity_backordered: 'Nachbestellt',
    quantity_cancelled: 'Abgebrochen',
    quantity_shipped: 'Versendet',
    ivendix_order_id: 'iVendix ID',
    catalog: 'Katalog',
    source: 'Quelle',
    division: 'Abteilung',
    actual_ship_date: 'Tatsächliches Versanddatum',
    rep_name: 'Name des Vertreters',
    tracking_carrier: 'Speditions-Code',
    gross_amount: 'Bruttobetrag',
    discount_percent: 'Rabatt %',
    internal_code: 'Interner Code',
    requested_date: 'Gewünschtes Datum',
    net_amount_total: 'Gesamtbetrag (Netto)',
    freight_amount: 'Frachtbetrag',
    client_order_status: 'Auftragsstatus',
    ship_address: 'Versandadresse',
    ship_city: 'Versandstadt',
    ship_state: 'Versandstaat',
    warehouse_name: 'Lagername',
    client_carrier_code: 'Kunden-Speditions-Code',
    discount_1: 'Rabatt 1',
    discount_2: 'Rabatt 2',
    discount_3: 'Rabatt 3',
    brand: 'Marke',
    quantity_open: 'Menge Offen',
    amount_open: 'Betrag Offen',
    quantity_invoiced: 'Berechnete Menge',
    amount_invoiced: 'Berechneter Betrag',
    promo_code: 'Promo-Code',
    size_code: 'Größencode',
    due_date: 'Fälligkeitsdatum',
    quantity_ordered: 'Bestellte Menge',
    quantity_allocated: 'Zugewiesene Menge',
    available_date: 'Verfügbares Datum',
    bank_information: 'Bankdaten',
    shipment_number: 'Sendung #',
    quantity_shipped_sku: 'Versendete SKU',
    name: 'Name',
    customs_reference: 'Zollreferenz',
    currency_code: 'Währungscode',
    id_model_partenaire_1: 'Partner-Modell-ID',
    id_model_partenaire_2: 'Partner-Modell-ID 2',
    expected_ship_date_range: 'Voraussichtliches Versanddatum',
    hs_code: 'HS-Code',
  }),
);
