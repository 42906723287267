define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "dojo/topic",
  "dojo/cookie",
  "dojo/Stateful"
], function(
  declare,
  lang,
  array,
  topic,
  cookie,
  Stateful
) {

  return declare(Stateful, {

    settings: null,

    namespace: 'cookieSettingStore',

    constructor: function(params, namespace) {
      params = params || {};
      this.settings = [];
      for (var k in params) {
        if (params.hasOwnProperty(k)) {
          this.settings.push(k);
        }
      }

      if (namespace) {
        this.namespace = namespace;
      }
    },

    postscript: function() {
      this.inherited(arguments);
      this._readFromCookies();
      this._inited = true;
    },

    set: function(name, value) {
      if (!this._inited || typeof name !== 'string') {
        return this.inherited(arguments);
      }

      this._writeToCookie(name, value);
      this.inherited(arguments);
      topic.publish('/scramble/' + this.namespace + '/' + name, [value]);
    },

    _readFromCookies: function() {
      array.forEach(this.settings, lang.hitch(this, function(setting) {
        var value;
        if (value = cookie('scramble_' + this.namespace + '_' + setting)) {
          if (value === '__bool_false__') {
            value = false;
          } else if (value === '__bool_true__') {
            value = true;
          }

          this.set(setting, value);
        }
      }));
    },

    _writeToCookie: function(name, value) {
      var expiration = value ? 14 : -100;

      if (value === false) {
        value = '__bool_false__';
      }

      if (value === true) {
        value = '__bool_true__';
      }

      cookie('scramble_' + this.namespace + '_' + name, value,
        { expires: expiration, Secure: true, SameSite: 'Strict' });
    },

    _isWritten: function(name) {
      return !!cookie('scramble_' + this.namespace + '_' + name);
    }
  });

});