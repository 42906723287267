define(
  ({
    new_order_document: '彈性訂單',
    no_quantities: '你必須選擇最低為1之數量以提交訂單。',
    no_customer_number: '請選擇付款地址 ',
    no_location_number: '請選擇貨運地址 ',
    no_arrival_date: '請選擇開始貨運日期',
    no_cancel_date: '請選擇取消日期',
    invalid_cancel_date: '請選擇有效取消日期。',
    invalid_start_ship_date: '請選擇有效開始貨運日期',
    no_po_number: '請提供付款委託號碼：',
    po_number_duplicate: '每個訂單必須含獨特付款委託號碼。',
    invalid_po_number: '請輸入有效付款委託號碼',
    invalid_page_comment: '請輸入有效的註解',
    products_not_available: '你的購物車中包含未能於所選貨運日期送出之產品。',
    already_submitted: '此訂單已提交。',
    already_review: '你已將此訂單提交至代理，訂單將需經審閱。',
    shared_with: '此文件已與經銷商分享，為唯讀文件而不可提交',
    dealer_submit: '此文件正由代理審閱，不可提交',
    page: '頁面',
    shipment: '貨運',
    external_field: '請選擇付款###',
    credit_hold_explanation: '你現在的帳戶狀況可開立訂單，但你將不能提交。請聯絡你的信貸經理。',
    catalog_min_failure: '必須包含此目錄###個單位。',
    catalog_max_failure: '超越此目錄###個單位的限制。',
    catalog_min_amount_failure: '此目錄最低總訂單額為 ###。',
    catalog_max_amount_failure: '此目錄最高總訂單額為 ###。',
    catalog_price_group_failure: '由於訂單缺少價格組合，因此未能完成下單。',
    catalog_requires_embellishments: '全部項目必須有個人化設定。',
    arrive_on_is_in_past: '###之開始貨運日期已過。請更新至今天或以後。',
    shipping_account_number_invalid: '請輸入有效的貨運帳戶號碼',
    document_renamed: '已重新命名訂單',
    document_could_not_be_renamed: '訂單無法被重新命名。',
    has_placeholder_allocations: '部份項目無法在你要求的貨運日期前提供貨件。你必須先解決此問題才可繼續執行下一步。',
  }),
);
