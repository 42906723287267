define([
  'dojo/_base/lang',
  'dojo/_base/array',
  'scramble/env',
  'scramble-com/modules/product',
  'scramble-com/modules/date',
], (
  lang,
  array,
  env,
  product,
  date,
) => {
  const atsCalculatorFunctions = {
    levels: ['infinity', 'high', 'low', 'medium'],

    atsLabelMax(catalog) {
      const cat = catalog || env.get('document') && env.get('document').get('catalog');
      if (env.get('user') && env.get('user').get('type') == 'rep') {
        if (cat && cat.ats_label_max_rep) return cat.ats_label_max_rep;
        if (env.getFlag('atsLabelMaxRep', null) != null) return env.getFlag('atsLabelMaxRep', null);
        return (cat && cat.ats_label_max) ? cat.ats_label_max : env.getFlag('atsLabelMax', 99);
      }
      return (cat && cat.ats_label_max) ? cat.ats_label_max : env.getFlag('atsLabelMax', 99);
    },

    findDroppedStatus(variation) {
      return variation.is_dropped
          && !array.some(variation.stock_items, (stockItem) => array.some(stockItem.quantities, (q) => q.quantity > 0));
    },
  };

  const atsCalculator = function (
    pageItemOrStockItem,
    arriveOn,
    user,
    variation,
    exactDateOnly,
    providedCatalog,
  ) {
    const doc = env.get('document');
    const catalog = env.contextCatalog || (doc && doc.get('catalog')) || providedCatalog;
    const arriveOnDate = date.toDate(arriveOn);
    // this isn't quite right, but we're not using this function for UI
    // purposes anymore so i'm not gonna worry about it
    const labelMax = (catalog || {}).ats_label_max || 99;
    const stockItem = pageItemOrStockItem.stockItem
      ? pageItemOrStockItem.stockItem
      : pageItemOrStockItem;

    const catalogType = catalog && catalog.type;

    const allowFuture = env.getFlag('atsAllowFuture');

    if (catalogType === 'blocking' && allowFuture) {
      return 'allowFuture';
    }

    variation = variation || pageItemOrStockItem.variation || null;

    const variationAvailableOn = variation
        && variation.available_on
        && date.toDate(variation.available_on) || null;

    const catalogAtsMode = (
      env.getFlag('atsLogic') === 'product'
        && variation
        && product.atsModeForVariation({
          variation,
          arriveOnDate,
          catalogAtsMode: null,
        })) || catalogType;

    return product.atsInfo({
      stockItem,
      catalogAtsMode,
      arriveOnDate,
      labelMax,
      exactDateOnly,
      variationAvailableOn,
      warehouse: null,
      enforceVariationAvailableOnForAvailability: env.getFlag('enforceVariationAvailableOnForAvailability'),
    });
  };

  // This sets the the properties of the atsCalculatorFunctions object onto the
  // atsCalculator function so that the related functions can be overriden at
  // another place in the code. The 'this' variable only is set correctly because
  // this first function call is using a lang.hitch
  return lang.mixin(atsCalculator, atsCalculatorFunctions);
});
