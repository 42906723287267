define(
  ({
    continue: 'Pokračovat',
    cancel: 'Zrušit',
    current_customization_will_be_reset: 'Aktuální přizpůsobení bude resetováno',
    rogue_warning: 'Pokud budete pokračovat, vaše aktuální konfigurace se vynuluje a proces přizpůsobení se spustí znovu. Opravdu chcete pokračovat?',
    copy: 'Kopírovat',
    paste: 'Vložit',
    copyToAll: 'Kopie všem',
    add_to_cart: 'Přidat do košíku',
    addToCart: 'Přidat do košíku',
    remove: 'Odebrat',
    added: 'Přidáno',
    dropped: 'Vynecháno',
    units: 'Jednotky',
    total: 'Celkem',
    subtotal: 'Mezisoučet',
    moveToShipment: 'Přesunout k zásilce',
    chooseOption: 'Vyberte možnost',
    availability_msg: 'Některá požadovaná množství nejsou k vašemu datu odeslání k dispozici.',
    atsWarning: 'Toto množství nebude k dispozici do %{availableOn}.',
    inventory_warehouse: 'Skladové zásoby',
    notInCatalog: 'Nelze přidat položku do košíku',
    notInCatalogExplanation: 'Litujeme, ale přidávání položek do katalogů není k dispozici.',
    digital_market_notInCatalogExplanation: 'Váš košík může v danou dobu obsahovat pouze položky od jedné značky',
    removeFromCartSuggestion: 'Chcete <link> vyprázdnit svůj košík?</link>',
    digital_market_removeFromCartSuggestion: 'Pro přidání této položky můžete <link>vyprázdnit košík</link>',
    emptyCart: 'Prázdný košík',
    empty: 'Prázdné',
    emptyConfirmation: 'Opravdu chcete vyprázdnit svůj košík?',
    available_on: 'K dispozici dne',
    personalization: 'Přizpůsobení',
    personalize: 'Přizpůsobit',
    configuration: 'Konfigurace',
    configure: 'Přizpůsobit',
    placement: 'Umístění',
    content: 'Obsah',
    color: 'Barva',
    product_specification: 'Specifikace produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, Logo',
    other: 'Jiné',
    value_might_be_stale: 'Z důvodu změn ve vašem košíku může být tato hodnota zastaralá. Uložit objednávku k přepočítání.',
    more_details: 'Další podrobnosti',
    availability: 'Dostupnost',
    no_scheduled_availability: 'Žádná naplánovaná dostupnost',
    not_available_until: 'Není k dispozici do <date />',
    available_as_of: 'K dispozici od <date />',
    units_bundled_singular_label: '%{units} jednotky v sadě',
    units_bundled_label: '%{units} jednotek v sadě',
    add_bundle_to_cart: 'Přidat sadu do košíku',
    bundles: 'Sady',
    bundled: 'V sadě',
    product: 'Produkt',
    sizes_and_quantities: 'Velikost / množství',
    units_singular_label: '%{units} jednotka',
    units_label: '%{units} jednotky',
    current_availability: 'Aktuální dostupnost',
    future_availability: 'Budoucí dostupnost',
    size: 'Velikost',
    sku: 'SKU',
    availability_date: 'Datum dostupnosti',
    quantity: 'Množství.',
    availability_subject_to_change: 'Dostupnost se může změnit',
    future_availability_subject_to_change: '*Dostupnost se může změnit a zahrnuje smluvní zásoby.',
    contract_inventory_availability: 'Dostupnost smluvních zásob**',
    contract_inventory_footer: '**Dostupnost smluvních zásob bude aktualizována po odeslání objednávky.',
    yes: 'Ano',
    no: 'Ne',
    copy_to_all_confirmation_message: 'Opravdu chcete tento rozsah velikostí zkopírovat do všech níže uvedených stylů?',
    no_configurator_data: 'Nejsou k dispozici žádná data konfigurátoru',
    oops: 'Jejda!',
    something_went_wrong: 'Něco se pokazilo!',
    error_message: 'Nebojte se, kontaktovali jsme za vás podporu a informovali ji o této chybě!',
    customize_item: 'Přizpůsobit položku',
    per_item_upgrades: 'Upgrady na položku',
    number_of_items: 'Počet položek',
    upgrades_total: 'Celkem upgradů',
    total_price_message: '* Celková cena bude vypočtena v košíku.',
    customizer_summary: 'Souhrn konfigurace',
    save: 'Uložit',
    field_required: 'Pole %{field} je povinné',
    review_configuration: 'Přezkoumat konfiguraci',
    review_configurations: 'Přezkoumat konfigurace',
    dismiss: 'Zavřít',
    review: 'Přezkoumat',
    cyrp_changed: 'Dostupnost komponent ve vašich nakonfigurovaných produktech se mohla změnit. Zkontrolujte konfiguraci produktů.',
    cyrp_review_warning: 'Dostupnost komponent ve vašich nakonfigurovaných produktech se mohla změnit. Chcete-li pokračovat, zkontrolujte konfiguraci produktů.',
    quantity_not_available: 'Tato komponenta není v požadovaném množství momentálně k dispozici. Vyberte prosím jinou položku, která je skladem.',
    customized: 'Přizpůsobeno',
    personalized: 'Personalizováno',
    customization: 'Přizpůsobení',
    preview: 'Náhled',
    preview_design: 'Náhled návrhu',
    copy_preview_url: 'Kopírovat adresu URL náhledu',
    edit: 'Upravit',
  }),
);
