/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import env from 'scramble/env';
import userSessionTracer from 'scramble-com/modules/user-session-tracer';
import SessionController from 'scramble/controllers/SessionController';

const closeWindow = () => {
  // This _will_ work for some SSO users, however
  // window.close() only works under limited circumstances
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/close
  window.close();
};

const refresh = (redirectUrl) => {
  const params = new URLSearchParams(window.location.search);
  const campaignIdInParams = params.get('campaignId');
  if (campaignIdInParams) {
    params.delete('campaignId');
  }

  if (campaignIdInParams) {
    // need to remove campaignId from the url when being
    // redirected from the unsubscribe from campaign page
    history.replaceState(null, '', '?' + params);
  }

  setTimeout(() => {
    window.location.href = redirectUrl || '';
  }, 0);
};

export const logout = () => {
  userSessionTracer().interaction({ interaction: 'Log Out User' });

  const ctrl = new SessionController();

  // logging out nullifies env's user so we look this up before initiating logut
  const userLacksExternalId = !(env.get('user')?.externalId || false);
  const handleSSOLogout = (logoutEvent) => {
    if (logoutEvent) return JSON.parse(logoutEvent).redirect_url;
    if (userLacksExternalId) return;
    if (env.getFlag('singleSignOn.logoutBehavior') !== 'attemptCloseWindow') {
      return;
    }
    closeWindow();
  };

  ctrl.logout().then(handleSSOLogout).then(refresh);
};
