define({
  root: ({
    new_custom_catalog: 'New Collection',
    defaults: 'Default',
    favorites: 'Favorites',
  }),
  'zh-tw': true,
  zh: true,
  ja: true,
  fr: true,
  de: true,
  ko: true,
  it: true,
  cs: true,
  tr: true,
  pl: true,
  fi: true,
  hu: true,
  sk: true,
  'en-gb': true,
  nb: true,
  es: true,
  nl: true,
  pt: true,
  ru: true,
  sv: true,
});
