define([
  'dojo/i18n!./nls/common',
  'scramble-com/ui/public/version-change-modal',
  './env',
  './widgets/scramble-com-shim',
], (
  nls,
  { default: VersionChangeModal },
  env,
  {
    renderReact18Component,
    createWrappedComponent,
  },
) => {
  const testHelper = {
    test: () => alert('version checker module not ready, please try again in a few seconds'),
  };

  const documentDirty = () => Boolean(
    (env.get('document') && env.get('document').get('dirty'))
      || (env.get('customCatalog') && env.get('customCatalog').get('dirty')),
  );

  const documentController = () => new Promise((resolve, reject) => {
    require(['scramble/controllers/Document'], (Controller) => resolve(Controller));
  });

  const customCatalogController = () => new Promise((resolve, reject) => {
    require(['scramble/controllers/CustomCatalog'], (Controller) => resolve(Controller));
  });

  const saveDocument = () => {
    const doc = env.get('document');
    const customCatalog = env.get('customCatalog');
    if (!doc && !customCatalog) return Promise.resolve();
    return doc
      ? documentController()
        .then((Controller) => new Controller({ model: doc }).save())
      : customCatalogController()
        .then((Controller) => new Controller({ model: customCatalog }).save());
  };

  env.whenReady().then(() => {
    env.whenDefined('version')
      // if any of the other values change, the build rev necessarily changes too
      .then(({ scramble_rev = '' } = {}) => {
        const modalContainer = document.getElementById('version_checker');
        const wrappedModalComponent = createWrappedComponent(nls, VersionChangeModal);

        const deployedScrambleBuildRev = () => fetch(`/version.json?t=${new Date().getTime()}`, { cache: 'no-store' })
          .then(res => res.json())
          .then(({ scramble_rev }) => scramble_rev)
          .catch(error => {
            console.error('error getting scramble version info', error);
            return 'N/A';
          });

        this.versionCheckerModalRoot = renderReact18Component({
          component: wrappedModalComponent,
          props: {
            documentDirty,
            saveDocument,
            scrambleBuildRev: scramble_rev,
            deployedScrambleBuildRev,
          },
          domNode: modalContainer,
          root: this.versionCheckerModalRoot,
        });

        testHelper.test = () => {
          this.versionCheckerModalRoot = renderReact18Component({
            component: wrappedModalComponent,
            props: {
              documentDirty,
              saveDocument,
              scrambleBuildRev: 'testing',
              deployedScrambleBuildRev,
              versionCheckInterval: 10000,
              userInteractionTimeout: 5000,
            },
            domNode: modalContainer,
            root: this.versionCheckerModalRoot,
          });
        };
      });
  });

  return testHelper;
});
