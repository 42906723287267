define(
  ({
    doc_type: 'Tipo de documento',
    doc_date: 'Fecha del documento',
    doc_number: 'Número de documento',
    ean_thirteen: 'EAN-13',
    supply_date: 'Fecha de suministro',
    discount: 'Descuento',
    sku: 'SKU',
    quantity_picked: 'Cantidad recogido',
    no_data_message: 'Sin registros',
    order_number: '# Pedido',
    elastic_order_number: 'Pedido Elastic #',
    line_number: 'Línea #',
    customer_name: 'Nombre de cliente',
    customer_number: '# cliente',
    location_number: '# ubicación',
    location_name: 'Nombre de ubicación',
    product: '# producto',
    style_name: 'Nombre del producto',
    code: 'Código de color',
    color: 'Nombre de color',
    status: 'Estado de línea',
    states: 'Estado de pedido',
    tracking: '# seguimiento',
    parcel_number: 'Paquete n.º',
    year: 'Año',
    season: 'Temporada',
    order_date: 'Fecha de creación',
    cancel_date: 'Cancelar por fecha',
    invoice_date: 'Fecha de factura',
    invoice: '# factura',
    invoice_url: 'URL de factura',
    download: 'Descargar',
    view: 'Ver',
    ship_date: 'Fecha de envío solicitada',
    expected_ship_date: 'Fecha de envío esperada',
    terms_name: 'Nombre de términos',
    po_number: '# PO',
    size: 'Tamaño',
    alt_name: 'Longitud de la talla',
    upc: 'UPC',
    units: 'Cant.',
    unit_singular: 'Unidad',
    unit_plural: 'Unidades',
    amount: 'Cantidad',
    net_price: 'Neto',
    unit_price: 'Precio por unidad',
    unit_retail_price: 'Precio unitario de venta',
    orders: 'Pedidos',
    totals: 'Totales',
    total: 'Total',
    details: 'Detalles',
    open: 'Abrir',
    shipped: 'Enviado',
    cancelled: 'Cancelado',
    canceled: 'Cancelado',
    released: 'Aprobado',
    invoiced: 'Facturados',
    picked: 'Recogido',
    region: 'Región',
    order_type: 'Tipo de pedido',
    backorder_date: 'Fecha de pedido anterior',
    backorder: 'Pedido anterior',
    payment_terms: 'Términos del pago',
    ship_via: 'Enviar por',
    line_discount: 'Descuento Line',
    rma_number: '# RMA',
    cash_discount: 'Descuento en metálico',
    hold_for_confirmation: 'Espera para confirmación',
    credit_hold: 'Suspensión de crédito',
    order_notes: 'Notas de pedido',
    product_series: 'Serie de productos',
    product_category: 'Categoría de producto',
    product_sub_category: 'Subcategoría de producto',
    quantity_confirmed: 'Confirmado',
    quantity_backordered: 'Pedido pendiente',
    quantity_cancelled: 'Cancelado',
    quantity_shipped: 'Enviado',
    ivendix_order_id: 'ID iVendix',
    catalog: 'Catálogo',
    source: 'Fuente',
    division: 'División',
    actual_ship_date: 'Fecha de envío real',
    rep_name: 'Nombre de repetición',
    tracking_carrier: 'Código de transportista',
    gross_amount: 'Cantidad bruta',
    discount_percent: 'Descuento %',
    internal_code: 'Código interno',
    requested_date: 'Fecha solicitada',
    net_amount_total: 'Importe neto total',
    freight_amount: 'Importe de la carga',
    client_order_status: 'Estado del pedido',
    ship_address: 'Dirección de entrega',
    ship_city: 'Ciudad de entrega',
    ship_state: 'Estado de entrega',
    warehouse_name: 'Nombre del almacén',
    client_carrier_code: 'Código de transportista del cliente',
    discount_1: 'Descuento 1',
    discount_2: 'Descuento 2',
    discount_3: 'Descuento 3',
    brand: 'Marca',
    quantity_open: 'Cantidad abierta',
    amount_open: 'Importe abierto',
    quantity_invoiced: 'Cantidad facturada',
    amount_invoiced: 'Importe facturado',
    promo_code: 'Código promocional',
    size_code: 'Código de tamaño',
    due_date: 'Fecha de vencimiento',
    quantity_ordered: 'Cantidad pedida',
    quantity_allocated: 'Cantidad asignada',
    available_date: 'Fecha disponible',
    bank_information: 'Información bancaria',
    shipment_number: 'Envío núm.',
    quantity_shipped_sku: 'SKU enviado',
    name: 'Nombre',
    customs_reference: 'Referencia para aduanas',
    currency_code: 'Código de moneda',
    id_model_partenaire_1: 'ID de modelo del socio',
    id_model_partenaire_2: 'ID de modelo del socio 2',
    expected_ship_date_range: 'Rango de fecha de envío esperada',
    hs_code: 'Código HS',
  }),
);
