define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "scramble/models/customCatalog/Item",
  "dojox/mvc/StatefulArray",
  "dojo/when",
], function (
  declare,
  lang,
  array,
  Item,
  StatefulArray,
  when,
) {

  let assetIdx = 0;

  var UserImage = declare(Item, {
    type: 'UserImage',

    constructor: function () {
      this._fields = lang.mixin({}, this._fields, {
        asset: null,
        title: null
      });
    },

    postscript: function (params) {
      lang.mixin(this, this._fields);
      if (params && params._id && !params.set) {
        this.unserialize(params);
      } else if (params && params.serialized) {
        this.unserialize(params);
        delete params.serialized;
        this.inherited(arguments);
      } else {
        this.inherited(arguments);
      }

      this.idx = assetIdx++;

      setTimeout(lang.hitch(this, function () {
        this.watch(lang.hitch(this, '_onChange'));
      }), 10);
    },

    _onChange: function (name, oldValue, newValue) {
      this.onChange.apply(this, arguments);
    },

    onChange: function () { },

    serialize: function () {
      return lang.mixin(this.inherited(arguments), {
        height: 700,
        width: 1920,
        name: this.asset.name,
        position: this.position,
        url: this.asset.urls.thumbnail,
        _type: this.type,
      });
    },

    unserialize: function (serialized) {
      this.inherited(arguments);
      var obj = {
        asset: {
          ...serialized,
          key: serialized.url,
          name: serialized.name,
          urls: {
            thumbnail: serialized.url,
            detail: serialized.url,
          }
        }
      };

      this.set(obj);
    },

    _uniqueKeyGetter: function () {
      return "custCatAsset:" + this.idx + ":" + (this.title || (this.asset ? this.asset.name : "noTitle")) +
        ":" + (this.asset ? this.asset.key : "custom");
    }
  });

  Item.registerType('UserImage', UserImage);

  return UserImage;

});

