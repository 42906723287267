define(["scramble/env", 'scramble-com/modules/env'], function(env, { support }) {
  return function() {
    window.open(
      support({
        supportUrl: env.getFlag('supportUrl'),
        supportEmail: env.getFlag('supportEmail')
      })
    );
  };
});
