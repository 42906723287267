define([
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/_base/kernel',
  'dojo/aspect',
  'scramble/has',
  'dojo/Deferred',
  'dojo/window',
  'dojo/dom-geometry',
  'dijit/Dialog',
  'scramble/widgets/Standby',
  'dojo/i18n!scramble/nls/common',
], (
  declare,
  lang,
  array,
  dojo,
  aspect,
  has,
  Deferred,
  window,
  domGeometry,
  Dialog,
  Standby,
  nls,
) => declare(Dialog, {
  draggable: false,

  title: null,

  nls,

  baseClass: 'scrambleModal',

  standby: null,

  dfd: null,

  useFancyResize: false,

  _relativePosition: null,

  constructor() {
  },

  buildRendering() {
    this.inherited(arguments);
    this.containerNode.style.minHeight = '0';
    this.containerNode.style.minWidth = '200px';
    this.standby = new Standby({ target: this.domNode });
    this.standby.placeAt(dojo.body(), 'last');
  },

  postCreate() {
    this.inherited(arguments);

    aspect.after(this, 'resize', lang.hitch(this, function () {
      if (!this.paneContentNode || !this.useFancyResize) {
        return;
      }

      const containerGeo = domGeometry.position(this.containerNode);

      let actionsHeight = 0;
      if (this.actions) {
        actionsHeight = domGeometry.getMarginBox(this.actions).h;
      }

      this.paneContentNode.style.height = `${containerGeo.h + actionsHeight}px`;
      this.paneContentNode.style.top = this.containerNode.style.top;
      this.containerNode.style.top = '';
      this.containerNode.style.height = '';
      this.containerNode.style.width = '';
      this.containerNode.style.position = '';
      this._layoutChildren();
      this.containerNode.style.overflowX = 'hidden';
      this.containerNode.style.overflowY = 'auto';
    }));
  },

  _setInitialPosition() {
    const viewport = window.getBox();
    const bb = domGeometry.position(this.domNode);
    const l = Math.floor((viewport.w - bb.w) / 2);
    const t = Math.floor((viewport.h - bb.h) / 2);

    this._relativePosition = { y: 20, x: l };
  },

  show() {
    this.inherited(arguments);

    if (has('touch')) {
      this._setInitialPosition();
      this._position();
    }

    this.dfd = this.dfd || (new Deferred());
    return this.dfd;
  },

  hide() {
    this.inherited(arguments);
    this.dfd = null;
  },

  loading() {
    this.standby.show();
  },

  doneLoading() {
    this.standby.hide();
  },

  success() {
    this.doneLoading();
    this.dfd.resolve.apply(this, arguments);
  },

  failure(...args) {
    this.doneLoading();
    this.dfd.reject.apply(this, ['userCancelled', ...args]);
  },

  _setContent(content) {
    if (content
        && (typeof content === 'string' || content.childNodes.length)) {
      return this.inherited(arguments);
    }
  },
}));
