define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "dojox/app/Controller",
  "dojo/Stateful",
  "scramble/env",
  "dojox/mvc/sync"
], function(
  declare,
  lang,
  array,
  Controller,
  Stateful,
  env,
  sync
) {

  return declare([Controller, Stateful], {
    constructor: function(app, events) {
      sync(env, 'document', this, 'document', {bindDirection: sync.from});
      sync(env, 'customCatalog', this, 'customCatalog', {bindDirection: sync.from});

      this.set({
        "document": {},
        customCatalog: {}
      });
    },

    _documentSetter: function(document) {
      this._bindItem(document);
      this._renderTitle();
    },

    _customCatalogSetter: function(customCatalog) {
      this._bindItem(customCatalog);
      this._renderTitle();
    },

    _itemWatches: null,
    _bindItem: function(item) {
      this.item = item;

      if (this._itemWatches) {
        // somehow firefox gets undefined elements in this array, i think when item
        // is null or something? anyway, FF's debugger is too lame for me to be
        // able to figure it out, so we're doing this for now
        array.forEach(array.filter(this._itemWatches, 'return !!item;'), 'item.remove();');
        this._itemWatches = [];
      }

      if (item && item.watch) {
        this._itemWatches = [
          item.watch('name', lang.hitch(this, '_renderTitle')),
          item.watch('dirty', lang.hitch(this, '_renderTitle'))
        ];
      }

      this._renderTitle();
    },

    // sets the document title to the name in the client config
    _renderTitle: function() {
      var str = env.getClientName();

      if (this.item && this.item.name) {
        var name = this.item.name;
        var state = this.item.dirty ? '*' : '';

        str = name + state + ' :: ' + str;
      }

      document.title = str;
    }
  });

});
