define(
  ({
    new_order_document: 'New Order',
    no_quantities: 'You must have a selected quantity of at least 1 in order to submit an order.',
    no_customer_number: 'Please select a Billing Address for ',
    no_location_number: 'Please select a Shipping Address for ',
    no_arrival_date: 'Please select a Start Ship Date for',
    no_cancel_date: 'Please select a Cancel Date for',
    invalid_cancel_date: 'Please Select a valid cancel date.',
    invalid_start_ship_date: 'Please select a valid Start Ship Date for',
    no_po_number: 'Please enter a PO Number for',
    po_number_duplicate: 'PO Numbers must be unique for each order.',
    invalid_po_number: 'Please enter a valid PO number',
    invalid_page_comment: 'Please enter a valid Comment',
    products_not_available: 'You have products in your cart which will not be available by your selected ship date.',
    already_submitted: 'This order has already been submitted.',
    already_review: 'You have already submitted this order to your rep, it needs to be reviewed.',
    shared_with: 'The document has been shared with a dealer. It is read only and cannot be submitted',
    dealer_submit: 'The document is pending review by your rep and cannot be submitted',
    page: 'Page',
    shipment: 'Shipment',
    external_field: 'Please select ### for ',
    credit_hold_explanation: 'With your current account status you are able to create an order, but you will not be able to submit it. Please contact your Credit Manager.',
    catalog_min_failure: 'Must have at least ### units for this catalogue.',
    catalog_max_failure: 'Exceeded limit of ### units for this catalogue.',
    catalog_min_amount_failure: 'The min total order amount for this catalogue is ###.',
    catalog_max_amount_failure: 'The max total order amount for this catalogue is ###.',
    catalog_price_group_failure: 'Order can not be placed due to missing price group.',
    catalog_requires_embellishments: 'All items must be personalised.',
    arrive_on_is_in_past: 'The Start Ship Date is in the past for ###. Please update this to be today or in the future.',
    shipping_account_number_invalid: 'Please enter a valid shipping account number',
    document_renamed: 'Order renamed.',
    document_could_not_be_renamed: 'Order could not be renamed.',
    has_placeholder_allocations: 'Some items are not available by your requested ship date. In order to continue, this issue must be resolved.',
  }),
);
