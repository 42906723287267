define(
  ({
    doc_type: 'Asiakirjan tyyppi',
    doc_date: 'Asiakirjan päivämäärä',
    doc_number: 'Asiakirjan numero',
    ean_thirteen: 'EAN-13',
    supply_date: 'Toimituspäivä',
    discount: 'Alennus',
    sku: 'Varastoyksikkö',
    quantity_picked: 'Poimittu lukumäärä',
    no_data_message: 'Ei tallenteita',
    order_number: 'Tilaus #',
    elastic_order_number: 'Elastic-tilaus #',
    line_number: 'Rivi #',
    customer_name: 'Asiakkaan nimi',
    customer_number: 'Asiakas #',
    location_number: 'Sijainti #',
    location_name: 'Sijaintipaikan nimi',
    product: 'Tuote #',
    style_name: 'Tuotteen nimi',
    code: 'Värikoodi',
    color: 'Värin nimi',
    status: 'Rivin tila',
    states: 'Tilauksen tila',
    tracking: 'Seuranta #',
    parcel_number: 'Paketti #',
    year: 'Vuosi',
    season: 'Vuodenaika',
    order_date: 'Luontipäivä',
    cancel_date: 'Peruuta päivämäärän perusteella',
    invoice_date: 'Laskutuspäivä',
    invoice: 'Laskutus #',
    invoice_url: 'Laskun URL',
    download: 'Lataa',
    view: 'Näytä',
    ship_date: 'Pyydetty toimituspäivä',
    expected_ship_date: 'Oletettu toimituspäivä',
    terms_name: 'Ehtojen nimi',
    po_number: 'Postinumero',
    size: 'Koko',
    alt_name: 'Koon pituus',
    upc: 'UPC',
    units: 'Lkm',
    unit_singular: 'Yksikkö',
    unit_plural: 'Yksikköä',
    amount: 'Summa',
    net_price: 'Netto',
    unit_price: 'Yksikköhinta',
    unit_retail_price: 'Yksikön vähittäismyyntihinta',
    orders: 'Tilaukset',
    totals: 'Summat',
    total: 'Yhteensä',
    details: 'Lisätiedot',
    open: 'Avaa',
    shipped: 'Toimitettu',
    cancelled: 'Peruttu',
    canceled: 'Peruttu',
    released: 'Vapautettu',
    invoiced: 'Laskutettu',
    picked: 'Poimittu',
    region: 'Alue',
    order_type: 'Tilauksen tyyppi',
    backorder_date: 'Jälkitoimituksen päivämäärä',
    backorder: 'Jälkitoimitus',
    payment_terms: 'Maksuehdot',
    ship_via: 'Toimitustapa',
    line_discount: 'Rivin alennus',
    rma_number: 'RMA #',
    cash_discount: 'Raha-alennus',
    hold_for_confirmation: 'Pidätä vahvistusta varten',
    credit_hold: 'Luottopidätys',
    order_notes: 'Tilauksen muistiinpanot',
    product_series: 'Tuotesarjat',
    product_category: 'Tuotekategoria',
    product_sub_category: 'Tuotteiden alakategoria',
    quantity_confirmed: 'Vahvistettu',
    quantity_backordered: 'Jälkitoimitettu',
    quantity_cancelled: 'Peruttu',
    quantity_shipped: 'Toimitettu',
    ivendix_order_id: 'iVendix-tunniste',
    catalog: 'Katalogi',
    source: 'Lähde',
    division: 'Osa-alue',
    actual_ship_date: 'Varsinainen toimituspäivä',
    rep_name: 'Edustajan nimi',
    tracking_carrier: 'Lähettikoodi',
    gross_amount: 'Bruttosumma',
    discount_percent: 'Alennus %',
    internal_code: 'Sisäinen koodi',
    requested_date: 'Pyyntöpäivä',
    net_amount_total: 'Kokonaisnettosumma',
    freight_amount: 'Rahtimäärä',
    client_order_status: 'Tilauksen tila',
    ship_address: 'Toimitusosoite',
    ship_city: 'Toimituskunta',
    ship_state: 'Toimituksen tila',
    warehouse_name: 'Varaston nimi',
    client_carrier_code: 'Asiakkaan lähettikoodi',
    discount_1: 'Alennus 1',
    discount_2: 'Alennus 2',
    discount_3: 'Alennus 3',
    brand: 'Tuotemerkki',
    quantity_open: 'Avoin lukumäärä',
    amount_open: 'Avoin summa',
    quantity_invoiced: 'Laskutettu lukumäärä',
    amount_invoiced: 'Laskutettu summa',
    promo_code: 'Promootiokoodi',
    size_code: 'Kokokoodi',
    due_date: 'Eräpäivä',
    quantity_ordered: 'Tilattu lukumäärä',
    quantity_allocated: 'Allokoitu lukumäärä',
    available_date: 'Saatavilla oleva päivämäärä',
    bank_information: 'Pankin tiedot',
    shipment_number: 'Toimitus #',
    quantity_shipped_sku: 'Toimitetut varastoyksiköt',
    name: 'Nimi',
    customs_reference: 'Tulliviite',
    currency_code: 'Valuuttakoodi',
    id_model_partenaire_1: 'Yhteistyökumppanin mallitunnus',
    id_model_partenaire_2: 'Yhteistyökumppanin mallitunnus 2',
    expected_ship_date_range: 'Oletettu toimituspäiväväli',
    hs_code: 'HS-koodi',
  }),
);
